<div class="traveller-details-page-container theme-steps">
  <racv-app-header></racv-app-header>
  <!-- Step Count  -->
  <app-step-bar
    [customStyles]="{ width: '33%' }"
    [activeStep]="'step2'"
  ></app-step-bar>

  <section class="quote selection">
    <form
      [formGroup]="travelerDetailsForm"
      (ngSubmit)="redirectToTravelerDetails()"
      id="travelerDetails"
    >
      <div class="container mt-5 p-3">
        <div class="row justify-content-center">
          <div class="col-md-11 col-11 borderpre p-2 p-md-3 p-lg-5">
            <div class="row justify-content-center">
              <div class="col-md-12 col-11 p-3 pregraybg">
                <h3>Medical Conditions Declaration</h3>
                <p>
                  Do you or any other declared traveller have a
                  <span
                    class="fw-bold text-info cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#existingMedConditionDef"
                    ><u>Medical Condition?</u></span
                  ><br />
                </p>
                <p>
                  We automatically cover You for 38 Medical Conditions without
                  the need for a medical assessment, providing that You:
                </p>
                <a
                  href="#"
                  #collapseTrigger
                  class="centerreadmore collapsed fw-bold mt-5"
                  data-bs-toggle="collapse"
                  data-bs-target="#readcarefully"
                  >Read full description carefully<br /><img
                    src="assets/icons/ract/readmore.png"
                    class="mt-3"
                /></a>
                <div class="collapse expand-content" id="readcarefully">
                  <ol>
                    <li>
                      have not required hospitalisation or treatment (where
                      treatment does not include an annual or routine medical
                      check-up, blood testing or a visit to a medical
                      practitioner to obtain a regular prescription) by
                      any <strong><em>Treating Doctor</em> </strong> within the
                      last 24 months (unless a different time-period is
                      specifically listed in the list below) for the <strong
                        ><em>Medical Condition;</em></strong
                      >
                    </li>
                    <li>
                      are not awaiting the outcome of any investigation, tests,
                      surgery or other treatment for the <strong
                        ><em>Medical Condition;</em></strong
                      >
                      and
                    </li>
                    <li>
                      meet any additional criteria set out in the <em
                        ><strong>Medical Conditions</strong> we automatically
                        cover</em
                      >
                      listed below.
                    </li>
                  </ol>

                  <p>
                    Where you are providing other individuals personal medical
                    information, we rely on you to obtain their consent to
                    provide this information. We also rely on you to inform them
                    of our privacy notice.
                  </p>
                  <button
                    type="button"
                    class="btn btn-outline-info rounded-pill"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    Show list of 38 automatically covered conditions
                  </button>
                  <p class="mt-2"><strong>Please note:</strong></p>
                  <ol>
                    <li>
                      If your condition meets the automatically covered
                      conditions criteria, there is nothing further you need to
                      do in order to be covered for that condition.
                    </li>
                    <li>
                      If your condition is not listed above or does not meet the
                      automatically covered conditions criteria, and you want
                      cover for this, you must complete a medical assessment to
                      assess whether we can accept this risk and what, if any
                      additional premium is payable by you, to have that
                      condition specified on your policy.
                    </li>
                  </ol>
                  <p>
                    You have a duty to take reasonable care not to make a
                    misrepresentation. It is important that you answer honestly,
                    accurately, and with reasonable care. You are answering for
                    yourself and anyone else insured under this policy. <em
                      >You can view the full duty to take reasonable care not to
                      make a misrepresentation in the PDS.</em
                    >
                  </p>
                  <p>
                    <b>
                      Do you or any declared traveller have any other
                      <span
                        class="fw-bold text-info cursor-pointer"
                        data-bs-toggle="modal"
                        data-bs-target="#existingMedConditionDef"
                        ><u>Medical Conditions</u></span
                      >
                      not in the automatically covered conditions that they want
                      covered?
                    </b>
                    <br /><br />
                    <strong>
                      Please note that if you decline to have the medical
                      conditions covered, you will not be covered for any event
                      arising from or related to this medical condition.
                    </strong>
                  </p>

                  <input
                    type="radio"
                    class="btn-check"
                    name="medical_con"
                    id="medical_con_yes"
                    formControlName="medical_con"
                    value="yes"
                    *ngIf="travelerDetailsForm.get('medical_con')"
                    (click)="triggerSegmentEvent('exstMedConYes')"
                  />
                  <label
                    class="btn btn-outline-info rounded-pill btn-lg px-5"
                    [ngClass]="{ active: medConditionSelected == 'yes' }"
                    for="medical_con_yes"
                    >Yes</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    name="medical_con"
                    id="medical_con_no"
                    formControlName="medical_con"
                    value="no"
                    *ngIf="travelerDetailsForm.get('medical_con')"
                    (click)="triggerSegmentEvent('exstMedConNo')"
                  />
                  <label
                    class="btn btn-outline-info rounded-pill btn-lg px-5"
                    [ngClass]="{ active: medConditionSelected == 'no' }"
                    for="medical_con_no"
                    >No</label
                  >
                  <p
                    *ngIf="medConditionSelected == 'no'"
                    style="color: #ba3030"
                  >
                    <br />
                    <b>
                      You are automatically covered for 38 Medical Conditions.
                      Any Medical Condition not declared will result in you
                      having no cover for any claims arising from, related to,
                      or made worse by that Medical Condition.
                    </b>
                  </p>
                  <p
                    *ngIf="
                      medConditionSelected == 'yes' &&
                      selectedCover?.selectedCoverTitle === 'Rental Car Excess'
                    "
                    style="color: #ba3030"
                  >
                    <br />
                    <b>
                      This option is not available for Rental Car Excess. Please
                      select 'No' to proceed to payment.
                    </b>
                  </p>
                </div>
              </div>
              <div
                [ngClass]="!isEnableForm ? 'disabledcontent' : ''"
                *ngIf="travelerDetailsForm.get('adult1')"
              >
                <div class="col-md-12 mt-5" id="discon1" formGroupName="adult1">
                  <p *ngIf="medConditionSelected == 'yes'">
                    <strong>
                      Please click ‘Yes’ to complete a Medical Assessment for
                      each declared traveller that has a Medical Condition that
                      is not automatically covered and they would like to apply
                      for coverage for that condition. Once you see any
                      adjustment to medical premium assessment pricing, please
                      click ‘Accept’ to obtain coverage or click ‘Decline’ to
                      decline coverage for the medical conditions disclosed.
                      Click 'No' if the traveller does not have a medical
                      condition to declare and would like to have covered by
                      following the process above.
                    </strong>
                  </p>
                  <br />
                  <h2 class="travelperson">
                    Adult 1
                    <small class="text-secondary">(Primary Traveller)</small>
                  </h2>
                  <div class="row">
                    <div class="col-md-3 col-12">
                      <label class="text-secondary">First Name*</label>
                      <input
                        placeholder="First Name"
                        id="adult1_firstName"
                        class="form-control"
                        pInputText
                        type="text"
                        formControlName="firstName"
                        (blur)="
                          onBlurNameField(
                            travelerDetailsForm,
                            'adult1.firstName'
                          )
                        "
                        maxlength="50"
                        placeholder="Enter First Name"
                      />
                      <ng-container
                        *ngIf="
                          travelerDetailsForm.get('adult1.firstName')
                            ?.invalid &&
                          (travelerDetailsForm.get('adult1.firstName')?.dirty ||
                            travelerDetailsForm.get('adult1.firstName')
                              ?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.firstName')?.errors?.['required']"
                          >
                            {{ errorMessages['first_name']?.['required'] }}
                          </span>
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.firstName')?.errors?.['minlength']"
                          >
                            {{ errorMessages['first_name']?.['minLength'] }}
                          </span>
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.firstName')?.errors?.['nameValidationMessage']"
                          >
                            {{ travelerDetailsForm.get('adult1.firstName')?.errors?.['nameValidationMessage'] }}
                            <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                          </span>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-md-3 col-12">
                      <label class="text-secondary">Last Name*</label>
                      <input
                        placeholder="Last Name"
                        id="adult1_lastName"
                        class="form-control"
                        pInputText
                        type="text"
                        formControlName="lastName"
                        (blur)="
                          onBlurNameField(
                            travelerDetailsForm,
                            'adult1.lastName'
                          )
                        "
                        maxlength="50"
                        placeholder="Enter Last Name"
                      />
                      <ng-container
                        *ngIf="
                          travelerDetailsForm.get('adult1.lastName')?.invalid &&
                          (travelerDetailsForm.get('adult1.lastName')?.dirty ||
                            travelerDetailsForm.get('adult1.lastName')?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.lastName')?.errors?.['required']"
                          >
                            {{ errorMessages['last_name']?.['required'] }}
                            <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                          </span>
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.lastName')?.errors?.['minlength']"
                          >
                            {{ errorMessages['last_name']?.['minLength'] }}
                            <!-- {{ errorMessages['last_name']?.['minLength'] || 'Last name must be at least 2 characters long.' }} -->
                          </span>
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.lastName')?.errors?.['nameValidationMessage']"
                          >
                            {{ travelerDetailsForm.get('adult1.lastName')?.errors?.['nameValidationMessage'] }}
                            <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                          </span>
                        </small>
                      </ng-container>
                    </div>
                    <div class="col-md-3 col-12">
                      <label class="text-secondary dateofbirth"
                        >Date of Birth*</label
                      >
                      <p-calendar
                        placeholder="Date of Birth dd/mm/yyyy"
                        type="date"
                        id="adult1_dateOfBirth"
                        formControlName="dateOfBirth"
                        [minDate]="dobMinDate"
                        [maxDate]="dobMaxDate"
                        [showIcon]="true"
                        [iconDisplay]="'input'"
                        dataType="string"
                        dateFormat="dd/mm/yy"
                        (onInput)="onDateInput($event, 'primary')"
                        class="dob steptwo"
                        placeholder="Date of Birth dd/mm/yyyy"
                        (onSelect)="
                          validateBirthDateOnSelect($event, 'primary')
                        "
                        showOtherMonths="false"
                      >
                      </p-calendar>
                      <ng-container
                        *ngIf="
                          travelerDetailsForm.get('adult1.dateOfBirth')
                            ?.invalid &&
                          (travelerDetailsForm.get('adult1.dateOfBirth')
                            ?.dirty ||
                            travelerDetailsForm.get('adult1.dateOfBirth')
                              ?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.dateOfBirth')?.errors?.['required']"
                          >
                            {{ errorMessages['dob']?.['required'] }}
                          </span>
                        </small>
                      </ng-container>

                      <small class="text-danger" *ngIf="!isAdult1AgeValid">
                        <span
                          >{{(quoteEnquiry.isSingleTrip ? errorMessages['first_age']?.['min'] : errorMessages['first_age_multi']?.['min'])}}</span
                        >
                      </small>
                    </div>
                    <div class="col-md-3 col-12" *ngIf="showMemebershipNumber">
                      <label class="text-secondary">Membership Number*</label>
                      <input
                        placeholder="Membership Number"
                        id="membershipNumber"
                        class="form-control"
                        type="tel"
                        formControlName="membershipNumber"
                        (blur)="validateMembershipNumber($event)"
                        pKeyFilter="int"
                        maxlength="{{ membershipNoMaxLength }}"
                      />
                      <ng-container
                        *ngIf="
                          travelerDetailsForm.get('adult1.membershipNumber')
                            ?.invalid &&
                          (travelerDetailsForm.get('adult1.membershipNumber')
                            ?.dirty ||
                            travelerDetailsForm.get('adult1.membershipNumber')
                              ?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.membershipNumber')?.errors?.['required']"
                          >
                            {{ errorMessages['membership_number']?.['required'] }}
                            <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                          </span>
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.membershipNumber')?.errors?.['minlength']"
                          >
                            {{ errorMessages['membership_number']?.['minLength'] }}
                            <!-- {{ errorMessages['last_name']?.['minLength'] || 'Last name must be at least 2 characters long.' }} -->
                          </span>
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.membershipNumber')?.errors?.['maxlength']"
                          >
                            {{ errorMessages['membership_number']?.['max'] }}
                            <!-- {{ errorMessages['last_name']?.['minLength'] || 'Last name must be at least 2 characters long.' }} -->
                          </span>
                        </small>
                      </ng-container>
                    </div>
                  </div>

                  <div
                    class="row pt-4 align-items-center"
                    id="exmedyes1"
                    *ngIf="
                      medConditionSelected == 'yes' &&
                      selectedCover?.selectedCoverTitle !== 'Rental Car Excess'
                    "
                  >
                    <div class="col-md-5 col-12">
                      <strong>Add cover for a Medical Condition?</strong>
                    </div>
                    <div class="col-md-7 col-12">
                      <input
                        type="radio"
                        class="btn-check"
                        name="one_med_con"
                        id="Tr_one_med_con_yes"
                        formControlName="one_med_con"
                        value="yes"
                        (click)="handleTravelerMedConChange($event, 'primary')"
                      />
                      <label
                        class="btn btn-outline-info rounded-pill btn-lg px-5 mobhalf"
                        for="Tr_one_med_con_yes"
                        >Yes</label
                      >
                      <input
                        type="radio"
                        class="btn-check"
                        name="one_med_con"
                        id="Tr_one_med_con_no"
                        formControlName="one_med_con"
                        value="no"
                        (click)="handleTravelerMedConChange($event, 'primary')"
                        *ngIf="
                          !primaryHasAssessment ||
                          primaryAssessmentStatus === 'Offer Pending'
                        "
                      />
                      <label
                        class="btn btn-outline-info rounded-pill btn-lg px-5 mobhalf"
                        for="Tr_one_med_con_no"
                        *ngIf="
                          !primaryHasAssessment ||
                          primaryAssessmentStatus === 'Offer Pending'
                        "
                        >No</label
                      >
                      <label
                        *ngIf="
                          primaryHasAssessment &&
                          !primaryReassessment &&
                          travelerDetailsForm.get('adult1.one_med_con')
                            ?.value != 'no'
                        "
                        >{{ primaryAssessmentStatus }}
                        <a
                          href="#"
                          (click)="updateMedicalCoverage($event, 'primary')"
                          >Click here to update medical coverage</a
                        >
                      </label>
                      <label
                        *ngIf="
                          travelerDetailsForm.get('adult1.one_med_con')
                            ?.value === 'no'
                        "
                      >
                        No Medical Conditions Declaration</label
                      >
                      <br />
                      <a
                        class="text-danger"
                        href="#"
                        *ngIf="primaryReassessment"
                        (click)="reDoAssessment($event, 'primary')"
                        >The change made in your policy has resulted in the
                        recalculation of the medical premium. Please click here
                        to continue.</a
                      >
                      <br />
                      <ng-container
                        *ngIf="
                          travelerDetailsForm.get('adult1.one_med_con')
                            ?.invalid &&
                          (travelerDetailsForm.get('adult1.one_med_con')
                            ?.dirty ||
                            travelerDetailsForm.get('adult1.one_med_con')
                              ?.touched)
                        "
                      >
                        <small class="text-danger">
                          <span
                            *ngIf="travelerDetailsForm.get('adult1.one_med_con')?.errors?.['shouldSelectYesOrNo']"
                          >
                            {{travelerDetailsForm.get('adult1.one_med_con')?.errors?.['shouldSelectYesOrNo']}}
                          </span>
                        </small>
                      </ng-container>
                    </div>
                    <div
                      class="pt-3"
                      *ngIf="adult1MedConSelected && !primaryHasAssessment"
                    >
                      <div
                        class="mb-2"
                        *ngIf="
                          !travelerDetailsForm.get('adult1')?.valid ||
                          !isAdult1AgeValid
                        "
                      >
                        <small class="text-danger">
                          <span>
                            Please fill up required fields before starting the
                            assessment
                          </span>
                        </small>
                      </div>
                      <div class="mb-2" *ngIf="adult1MedicalRequired">
                        <small class="text-danger">
                          <span> Please complete the medical assessment </span>
                        </small>
                      </div>
                      <button
                        id="adult1StartMedicalButton"
                        class="btn btn-outline-info rounded-pill btn-lg px-2 px-md-5 mobfull"
                        [disabled]="
                          !travelerDetailsForm.get('adult1')?.valid ||
                          !isAdult1AgeValid
                        "
                        (click)="onStartMedicalAssesment($event, 'primary')"
                      >
                        Start Medical Assessment
                      </button>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="hasAdult2">
                  <!-- Hr Rule -->
                  <div class="col-12 py-2">
                    <hr />
                  </div>
                  <div class="col-md-12" id="discon2" formGroupName="adult2">
                    <h2 class="travelperson">
                      Adult 2<small class="text-secondary"
                        >(Secondary Traveller)</small
                      >
                    </h2>
                    <div class="row">
                      <div class="col-md-3 col-12">
                        <label class="text-secondary">First Name*</label>
                        <input
                          placeholder="First Name"
                          id="adult2_firstName"
                          class="form-control"
                          pInputText
                          type="text"
                          formControlName="firstName"
                          (blur)="
                            onBlurNameField(
                              travelerDetailsForm,
                              'adult2.firstName'
                            )
                          "
                          maxlength="50"
                          placeholder="Enter First Name"
                        />
                        <ng-container
                          *ngIf="
                            travelerDetailsForm.get('adult2.firstName')
                              ?.invalid &&
                            (travelerDetailsForm.get('adult2.firstName')
                              ?.dirty ||
                              travelerDetailsForm.get('adult2.firstName')
                                ?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="travelerDetailsForm.get('adult2.firstName')?.errors?.['required']"
                            >
                              {{ errorMessages['first_name']?.['required'] }}
                            </span>
                            <span
                              *ngIf="travelerDetailsForm.get('adult2.firstName')?.errors?.['minlength']"
                            >
                              {{ errorMessages['first_name']?.['minLength'] }}
                            </span>
                            <span
                              *ngIf="travelerDetailsForm.get('adult2.firstName')?.errors?.['nameValidationMessage']"
                            >
                              {{ travelerDetailsForm.get('adult2.firstName')?.errors?.['nameValidationMessage'] }}
                              <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                            </span>
                          </small>
                        </ng-container>
                      </div>
                      <div class="col-md-3 col-12">
                        <label class="text-secondary">Last Name*</label>
                        <input
                          placeholder="Last Name"
                          id="adult2_lastName"
                          class="form-control"
                          pInputText
                          type="text"
                          formControlName="lastName"
                          (blur)="
                            onBlurNameField(
                              travelerDetailsForm,
                              'adult2.lastName'
                            )
                          "
                          maxlength="50"
                          placeholder="Enter Last Name"
                        />
                        <ng-container
                          *ngIf="
                            travelerDetailsForm.get('adult2.lastName')
                              ?.invalid &&
                            (travelerDetailsForm.get('adult2.lastName')
                              ?.dirty ||
                              travelerDetailsForm.get('adult2.lastName')
                                ?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="travelerDetailsForm.get('adult2.lastName')?.errors?.['required']"
                            >
                              {{ errorMessages['last_name']?.['required'] }}
                              <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                            </span>
                            <span
                              *ngIf="travelerDetailsForm.get('adult2.lastName')?.errors?.['minlength']"
                            >
                              {{ errorMessages['last_name']?.['minLength'] }}
                              <!-- {{ errorMessages['last_name']?.['minLength'] || 'Last name must be at least 2 characters long.' }} -->
                            </span>
                            <span
                              *ngIf="travelerDetailsForm.get('adult2.lastName')?.errors?.['nameValidationMessage']"
                            >
                              {{ travelerDetailsForm.get('adult2.lastName')?.errors?.['nameValidationMessage'] }}
                              <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                            </span>
                          </small>
                        </ng-container>
                      </div>
                      <div class="col-md-3 col-12">
                        <label class="text-secondary">Date of Birth*</label>
                        <p-calendar
                          placeholder="Date of Birth dd/mm/yyyy"
                          id="adult2_dateOfBirth"
                          type="date"
                          formControlName="dateOfBirth"
                          [minDate]="dobMinDate"
                          [maxDate]="dobMaxDate"
                          [showIcon]="true"
                          [iconDisplay]="'input'"
                          dataType="string"
                          dateFormat="dd/mm/yy"
                          (onInput)="onDateInput($event, 'secondary')"
                          class="dob steptwo"
                          placeholder="Date of Birth dd/mm/yyyy"
                          (onSelect)="
                            validateBirthDateOnSelect($event, 'secondary')
                          "
                          showOtherMonths="false"
                        >
                        </p-calendar>
                        <ng-container
                          *ngIf="
                            travelerDetailsForm.get('adult2.dateOfBirth')
                              ?.invalid &&
                            (travelerDetailsForm.get('adult2.dateOfBirth')
                              ?.dirty ||
                              travelerDetailsForm.get('adult2.dateOfBirth')
                                ?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="travelerDetailsForm.get('adult2.dateOfBirth')?.errors?.['required']"
                            >
                              {{ errorMessages['dob']?.['required'] }}
                            </span>
                          </small>
                        </ng-container>
                        <small class="text-danger" *ngIf="!isAdult2AgeValid"
                          ><span
                            >{{(quoteEnquiry.isSingleTrip ? errorMessages['second_age']?.['min'] : errorMessages['second_age_multi']?.['min'])}}
                          </span>
                        </small>
                      </div>
                    </div>
                    <ng-container
                      *ngIf="isEnableForm && travelerDetailsForm?.errors?.['sameDetailsError']"
                    >
                      <small class="text-danger">
                        <span>
                          {{errorMessages['sameDetails']?.['required']}}
                        </span>
                      </small>
                    </ng-container>

                    <div
                      class="row pt-4 align-items-center"
                      id="exmedyes2"
                      *ngIf="
                        medConditionSelected == 'yes' &&
                        selectedCover?.selectedCoverTitle !==
                          'Rental Car Excess'
                      "
                    >
                      <div class="col-md-5">
                        <strong>Add cover for a Medical Condition?</strong>
                      </div>
                      <div class="col-md-7 col-12">
                        <input
                          type="radio"
                          class="btn-check"
                          name="two_med_con"
                          id="Tr_two_med_con_yes"
                          formControlName="two_med_con"
                          value="yes"
                          (click)="
                            handleTravelerMedConChange($event, 'secondary')
                          "
                        />
                        <label
                          class="btn btn-outline-info rounded-pill btn-lg px-5 mobhalf"
                          for="Tr_two_med_con_yes"
                          >Yes</label
                        >
                        <input
                          type="radio"
                          class="btn-check"
                          name="two_med_con"
                          id="Tr_two_med_con_no"
                          formControlName="two_med_con"
                          value="no"
                          (click)="
                            handleTravelerMedConChange($event, 'secondary')
                          "
                          *ngIf="
                            !secondaryHasAssessment ||
                            secondaryAssessmentStatus === 'Offer Pending'
                          "
                        />
                        <label
                          class="btn btn-outline-info rounded-pill btn-lg px-5 mobhalf"
                          for="Tr_two_med_con_no"
                          *ngIf="
                            !secondaryHasAssessment ||
                            secondaryAssessmentStatus === 'Offer Pending'
                          "
                          >No</label
                        >
                        <label
                          *ngIf="
                            secondaryHasAssessment &&
                            !secondaryReassessment &&
                            travelerDetailsForm.get('adult2.two_med_con')
                              ?.value != 'no'
                          "
                          >{{ secondaryAssessmentStatus }}
                          <a
                            href="#"
                            (click)="updateMedicalCoverage($event, 'secondary')"
                            >Click here to update medical coverage</a
                          ></label
                        >
                        <label
                          *ngIf="
                            travelerDetailsForm.get('adult2.two_med_con')
                              ?.value === 'no'
                          "
                        >
                          No Medical Conditions Declaration</label
                        >
                        <br />
                        <a
                          class="text-danger"
                          href="#"
                          *ngIf="secondaryReassessment"
                          (click)="reDoAssessment($event, 'secondary')"
                          >The change made in your policy has resulted in the
                          recalculation of the medical premium. Please click
                          here to continue.</a
                        >
                        <br />
                        <ng-container
                          *ngIf="
                            travelerDetailsForm.get('adult2.two_med_con')
                              ?.invalid &&
                            (travelerDetailsForm.get('adult2.two_med_con')
                              ?.dirty ||
                              travelerDetailsForm.get('adult2.two_med_con')
                                ?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="travelerDetailsForm.get('adult2.two_med_con')?.errors?.['shouldSelectYesOrNo']"
                            >
                              {{travelerDetailsForm.get('adult2.two_med_con')?.errors?.['shouldSelectYesOrNo']}}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                      <div
                        class="py-2"
                        *ngIf="adult2MedConSelected && !secondaryHasAssessment"
                      >
                        <div
                          class="mb-3"
                          *ngIf="
                            !travelerDetailsForm.get('adult1')?.valid ||
                            !travelerDetailsForm.get('adult2')?.valid ||
                            !isAdult2AgeValid
                          "
                        >
                          <small class="text-danger">
                            <span>
                              Please fill up required fields before starting the
                              assessment
                            </span>
                          </small>
                        </div>
                        <div class="mb-2" *ngIf="adult2MedicalRequired">
                          <small class="text-danger">
                            <span>
                              Please complete the medical assessment
                            </span>
                          </small>
                        </div>
                        <button
                          class="btn btn-outline-info rounded-pill btn-lg px-2 px-md-5 mobfull"
                          [disabled]="
                            !travelerDetailsForm.get('adult1')?.valid ||
                            !travelerDetailsForm.get('adult2')?.valid ||
                            !isAdult2AgeValid
                          "
                          (click)="onStartMedicalAssesment($event, 'secondary')"
                        >
                          Start Medical Assessment
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div class="col-md-12 col-11 mt-5 p-4 pregraybg" id="discon3">
                  <div class="row align-items-center">
                    <div class="col-md-4 col-12 mb-3 mb-md-0">
                      <button
                        type="button"
                        class="btn btn-outline-info rounded-pill btn-lg px-xxl-5 mobfull"
                        (click)="addNewDependent()"
                      >
                        Add Dependent
                      </button>
                    </div>
                    <div class="col-md-8 col-12">
                      Dependents such as children, grandchildren, step-children
                      and foster children aged 25 or under can be listed at no
                      extra cost, so long as they travel with you the whole
                      time, are financially dependent, not working full time,
                      and do not require a medical assessment.
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="dependents.controls.length > 0">
                  <div class="" formArrayName="dependents">
                    <div class="col-12">
                      <h2 class="travelperson">Dependents</h2>
                      <div
                        class="row pt-4"
                        *ngFor="
                          let dependent of dependents.controls;
                          let i = index
                        "
                        [formGroupName]="i"
                      >
                        <div class="col-md-3 col-12">
                          <label>First Name*</label>
                          <input
                            placeholder="First Name"
                            id="dep{{ i }}_firstName"
                            class="form-control"
                            pInputText
                            formControlName="firstName"
                            type="text"
                            (blur)="onBlurNameField(dependent, 'firstName')"
                            maxlength="50"
                            placeholder="Enter First Name"
                          />
                          <ng-container
                            *ngIf="
                              dependent.get('firstName')?.invalid &&
                              (dependent.get('firstName')?.dirty ||
                                dependent.get('firstName')?.touched)
                            "
                          >
                            <small class="text-danger">
                              <span
                                *ngIf="dependent.get('firstName')?.errors?.['required']"
                              >
                                {{ errorMessages['first_name']?.['required'] }}
                              </span>
                              <span
                                *ngIf="dependent.get('firstName')?.errors?.['minlength']"
                              >
                                {{ errorMessages['first_name']?.['minLength'] }}
                              </span>
                              <span
                                *ngIf="dependent.get('firstName')?.errors?.['nameValidationMessage']"
                              >
                                {{ dependent.get('firstName')?.errors?.['nameValidationMessage'] }}
                                <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                              </span>
                            </small>
                          </ng-container>
                        </div>
                        <div class="col-md-3 col-12">
                          <label>Last Name*</label>
                          <input
                            placeholder="Last Name"
                            id="dep{{ i }}_lastName"
                            class="form-control"
                            pInputText
                            formControlName="lastName"
                            type="text"
                            (blur)="onBlurNameField(dependent, 'lastName')"
                            maxlength="50"
                            placeholder="Enter Last Name"
                          />
                          <ng-container
                            *ngIf="
                              dependent.get('lastName')?.invalid &&
                              (dependent.get('lastName')?.dirty ||
                                dependent.get('lastName')?.touched)
                            "
                          >
                            <small class="text-danger">
                              <span
                                *ngIf="dependent.get('lastName')?.errors?.['required']"
                              >
                                {{ errorMessages['last_name']?.['required'] }}
                              </span>
                              <span
                                *ngIf="dependent.get('lastName')?.errors?.['minlength']"
                              >
                                {{ errorMessages['last_name']?.['minLength'] }}
                              </span>
                              <span
                                *ngIf="dependent.get('lastName')?.errors?.['nameValidationMessage']"
                              >
                                {{ dependent.get('lastName')?.errors?.['nameValidationMessage'] }}
                                <!-- {{ errorMessages['last_name']?.['required'] || 'Last name is required.' }} -->
                              </span>
                            </small>
                          </ng-container>
                        </div>
                        <div class="col-md-3 col-10">
                          <label for="dateField">Date of Birth*</label>
                          <p-calendar
                            placeholder="Date of Birth dd/mm/yyyy"
                            id="dep{{ i }}_dateOfBirth"
                            type="date"
                            formControlName="dateOfBirth"
                            [minDate]="dobMinDate"
                            [maxDate]="dobMaxDate"
                            [showIcon]="true"
                            [iconDisplay]="'input'"
                            dataType="string"
                            dateFormat="dd/mm/yy"
                            class="dob steptwo"
                            placeholder="Date of Birth dd/mm/yyyy"
                            showOtherMonths="false"
                          >
                          </p-calendar>
                          <ng-container
                            *ngIf="
                              dependent.get('dateOfBirth')?.invalid &&
                              (dependent.get('dateOfBirth')?.dirty ||
                                dependent.get('dateOfBirth')?.touched)
                            "
                          >
                            <small class="text-danger">
                              <span
                                *ngIf="dependent.get('dateOfBirth')?.errors?.['required']"
                              >
                                {{ errorMessages['dob']?.['required'] }}
                              </span>
                            </small>

                            <small class="text-danger">
                              <div
                                class="error-message"
                                *ngIf="dependent.get('dateOfBirth')?.errors?.['dependentAgeIsNotValid']"
                              >
                                {{dependent.get('dateOfBirth')?.errors?.['dependentAgeIsNotValid']}}
                              </div>
                            </small>
                          </ng-container>
                        </div>
                        <div class="col-md-3 col-2 my-auto mx-0">
                          <img
                            class="cancel-icon"
                            src="assets/images/ract/cancel.png"
                            (click)="removeDependents(i)"
                          />
                        </div>

                        <div
                          class="row pt-3 align-items-center"
                          *ngIf="
                            medConditionSelected == 'yes' &&
                            selectedCover?.selectedCoverTitle !==
                              'Rental Car Excess'
                          "
                        >
                          <div class="col-md-5 col-12">
                            <strong>Add cover for an Medical Condition?</strong>
                          </div>
                          <div class="col-md-7 col-12">
                            <input
                              type="radio"
                              class="btn-check"
                              id="Tr_dependent_med_con_yes_{{ i }}"
                              formControlName="dependent_med_con"
                              value="yes"
                              (click)="
                                handleMedConChange(
                                  getDependentFormGroup(i),
                                  'yes'
                                )
                              "
                            />
                            <label
                              class="btn btn-outline-info rounded-pill btn-lg px-5 mobhalf"
                              for="Tr_dependent_med_con_yes_{{ i }}"
                              >Yes</label
                            >
                            <input
                              type="radio"
                              class="btn-check"
                              id="Tr_dependent_med_con_no_{{ i }}"
                              formControlName="dependent_med_con"
                              value="no"
                              (click)="
                                handleMedConChange(
                                  getDependentFormGroup(i),
                                  'no'
                                )
                              "
                              *ngIf="
                                !dependent.get('hasAssessment')?.value ||
                                dependent.get('assessmentStatus')?.value ===
                                  'Offer Pending'
                              "
                            />
                            <label
                              class="btn btn-outline-info rounded-pill btn-lg px-5 mobhalf"
                              for="Tr_dependent_med_con_no_{{ i }}"
                              *ngIf="
                                !dependent.get('hasAssessment')?.value ||
                                dependent.get('assessmentStatus')?.value ===
                                  'Offer Pending'
                              "
                              >No</label
                            >
                            <label
                              *ngIf="
                                dependent.get('hasAssessment')?.value &&
                                !dependent.get('reassessment')?.value &&
                                dependent.get('dependent_med_con')?.value !=
                                  'no'
                              "
                            >
                              {{ dependent.get('assessmentStatus')?.value }}
                              <a
                                href="#"
                                (click)="
                                  updateMedicalCoverage($event, 'dependent', i)
                                "
                              >
                                Click here to update medical coverage</a
                              ></label
                            >
                            <label
                              *ngIf="
                                dependent.get('dependent_med_con')?.value ===
                                'no'
                              "
                            >
                              No Medical Conditions Declaration</label
                            >
                            <br />
                            <a
                              class="text-danger"
                              href="#"
                              *ngIf="dependent.get('reassessment')?.value"
                              (click)="reDoAssessment($event, 'dependent', i)"
                              >The change made in your policy has resulted in
                              the recalculation of the medical premium. Please
                              click here to continue.</a
                            >
                            <br />
                            <ng-container
                              *ngIf="
                                dependent.get('dependent_med_con')?.invalid &&
                                (dependent.get('dependent_med_con')?.dirty ||
                                  dependent.get('dependent_med_con')?.touched)
                              "
                            >
                              <small class="text-danger">
                                <span
                                  *ngIf="dependent.get('dependent_med_con')?.errors?.['shouldSelectYesOrNo']"
                                >
                                  {{dependent.get('dependent_med_con')?.errors?.['shouldSelectYesOrNo']}}
                                </span>
                              </small>
                            </ng-container>
                          </div>
                          <div
                            class="pt-3"
                            *ngIf="
                              dependent.get('medConSelected')?.value &&
                              !dependent.get('hasAssessment')?.value
                            "
                          >
                            <div
                              class="mb-2"
                              *ngIf="!getDependentFormGroup(i).valid ||
                              !travelerDetailsForm.get('adult1')?.valid"
                            >
                              <small class="text-danger">
                                <span>
                                  Please fill up required fields before starting
                                  the assessment
                                </span>
                              </small>
                            </div>
                            <div class="mb-2" *ngIf="depMedicalRequired[i]">
                              <small
                                class="text-danger"
                                *ngIf="depMedicalRequired[i]"
                              >
                                <span>
                                  Please complete the medical assessment
                                </span>
                              </small>
                            </div>
                            <button
                              class="btn btn-outline-info rounded-pill btn-lg px-2 px-md-5 mobfull"
                              aria-controls=""
                              [disabled]="!getDependentFormGroup(i).valid"
                              (click)="
                                onStartMedicalAssesment($event, 'dependent', i)
                              "
                            >
                              Start Medical Assessment
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="!isEnableForm ? 'disabledcontent' : ''">
          <div class="row justify-content-center mt-5" id="discon4">
            <div class="col-md-11 col-11 borderpre p-md-5 p-3">
              <div class="row justify-content-center">
                <div
                  class="col-md-12 col-12 p-3"
                  *ngIf="travelerDetailsForm.get('contactAndAddressDetails')"
                >
                  <div formGroupName="contactAndAddressDetails">
                    <h2 class="travelperson">
                      Contact Details of Primary Traveller
                    </h2>
                    <div class="row pt-4 pb-4">
                      <div class="col-md-8 col-12">
                        <p class="fw-bold">Email</p>
                        <div class="row">
                          <div class="col-md-6 col-12">
                            <label>Email Address*</label>
                            <input
                              id="emailId"
                              class="form-control"
                              formControlName="emailId"
                              pInputText
                              type="email"
                              placeholder="Enter email id"
                            />

                            <ng-container
                              *ngIf="
                                travelerDetailsForm.get(
                                  'contactAndAddressDetails.emailId'
                                )?.invalid &&
                                (travelerDetailsForm.get(
                                  'contactAndAddressDetails.emailId'
                                )?.dirty ||
                                  travelerDetailsForm.get(
                                    'contactAndAddressDetails.emailId'
                                  )?.touched)
                              "
                            >
                              <small class="text-danger">
                                <span
                                  *ngIf="travelerDetailsForm.get('contactAndAddressDetails.emailId')?.errors?.['required']"
                                >
                                  {{ errorMessages['email_id']?.['required'] }}
                                </span>
                                <span *ngIf="email?.errors?.['invalidEmail']">
                                  {{ errorMessages['email_id']?.['email'] }}
                                </span>
                              </small>
                            </ng-container>
                          </div>
                          <div class="col-md-6 col-12">
                            <label>Confirm Email Address*</label>
                            <input
                              id="confEmailId"
                              class="form-control"
                              formControlName="confEmailId"
                              pInputText
                              type="email"
                              placeholder="Confirm Email Address"
                              (paste)="disablePaste($event)"
                            />
                            <ng-container
                              *ngIf="
                                travelerDetailsForm.get(
                                  'contactAndAddressDetails.confEmailId'
                                )?.invalid &&
                                (travelerDetailsForm.get(
                                  'contactAndAddressDetails.confEmailId'
                                )?.dirty ||
                                  travelerDetailsForm.get(
                                    'contactAndAddressDetails.confEmailId'
                                  )?.touched)
                              "
                            >
                              <small class="text-danger">
                                <span
                                  *ngIf="travelerDetailsForm.get('contactAndAddressDetails.confEmailId')?.errors?.['required']"
                                >
                                  {{ errorMessages['conf_email_id']?.['required'] }}
                                </span>
                                <span
                                  *ngIf="confEmailId?.errors?.['invalidEmail']"
                                >
                                  {{ errorMessages['conf_email_id']?.['email'] }}
                                </span>
                              </small>
                            </ng-container>
                            <ng-container>
                              <small class="text-danger">
                                <span
                                  *ngIf="travelerDetailsForm.get('contactAndAddressDetails')?.errors?.['emailMismatch']"
                                >
                                  {{ errorMessages['conf_email_id']?.['emailMismatch'] }}
                                </span>
                              </small>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <p class="fw-bold">&nbsp;</p>
                        <div class="row">
                          <div class="col-12">
                            <label>Mobile Number*</label>
                            <div class="input-group">
                              <input
                                id="phoneNumber"
                                class="form-control"
                                formControlName="phoneNumber"
                                type="tel"
                                placeholder="Enter Mobile Number "
                                maxlength="10"
                                pKeyFilter="int"
                                (blur)="onBlurMobileNumber()"
                              />
                            </div>
                            <div class="feedback text-secondary">
                              <ng-container
                                *ngIf="
                                  travelerDetailsForm.get(
                                    'contactAndAddressDetails.phoneNumber'
                                  )?.invalid &&
                                  (travelerDetailsForm.get(
                                    'contactAndAddressDetails.phoneNumber'
                                  )?.dirty ||
                                    travelerDetailsForm.get(
                                      'contactAndAddressDetails.phoneNumber'
                                    )?.touched)
                                "
                              >
                                <small class="text-danger">
                                  <span
                                    *ngIf="travelerDetailsForm.get('contactAndAddressDetails.phoneNumber')?.errors?.['required']"
                                  >
                                    {{ errorMessages['phone_number']?.['required']
                                    }}
                                  </span>
                                  <span
                                    *ngIf="travelerDetailsForm.get('contactAndAddressDetails.phoneNumber')?.errors?.['phoneNumberInvalid']"
                                  >
                                    {{ errorMessages['phone_number']?.['phoneNumberInvalid']
                                    }}
                                  </span>
                                </small>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row pt-4 pb-4">
                      <p class="fw-bold">Address</p>
                      <div
                        class="col-md-12 col-12 mb-2 d-flex align-items-center"
                      >
                        <label>Search: </label> &nbsp;
                        <p-autoComplete
                          class="w-100"
                          styleClass="address-autocomplete"
                          [forceSelection]="true"
                          [suggestions]="addressSuggestions"
                          (completeMethod)="onAutocompleteAddress($event)"
                          (onSelect)="selectAddress($event)"
                          formControlName="address"
                          placeholder="Street Address  (Choose from the drop down list of address options as you type or type in your address)"
                        >
                          <ng-template
                            class="test"
                            let-message
                            pTemplate="empty"
                          >
                            <div *ngIf="isLoadingAddressify">
                              <p-progressBar
                                mode="indeterminate"
                              ></p-progressBar>
                            </div>
                          </ng-template>
                        </p-autoComplete>
                      </div>
                      <div class="col-md-3 col-12 mb-2">
                        <label>Street Address*</label>
                        <input
                          id="street"
                          class="form-control"
                          pInputText
                          formControlName="street"
                          type="text"
                          placeholder="Enter street name, house number etc."
                          aria-label="default input example"
                        />
                        <ng-container
                          *ngIf="
                            travelerDetailsForm.get(
                              'contactAndAddressDetails.street'
                            )?.invalid &&
                            (travelerDetailsForm.get(
                              'contactAndAddressDetails.street'
                            )?.dirty ||
                              travelerDetailsForm.get(
                                'contactAndAddressDetails.street'
                              )?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="travelerDetailsForm.get('contactAndAddressDetails.street')?.errors?.['required']"
                            >
                              {{ errorMessages['street']?.['required'] }}
                            </span>
                            <span
                              *ngIf="travelerDetailsForm.get('contactAndAddressDetails.street')?.errors?.['leadingSpace']"
                            >
                              {{ errorMessages['street']?.['leadingSpace'] }}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                      <div class="col-md-3 col-12">
                        <label>Suburb*</label>
                        <input
                          id="subUrb"
                          class="form-control"
                          formControlName="subUrb"
                          pInputText
                          type="text"
                          placeholder="Enter your suburb"
                          aria-label="default input example"
                        />
                        <ng-container
                          *ngIf="
                            travelerDetailsForm.get(
                              'contactAndAddressDetails.subUrb'
                            )?.invalid &&
                            (travelerDetailsForm.get(
                              'contactAndAddressDetails.subUrb'
                            )?.dirty ||
                              travelerDetailsForm.get(
                                'contactAndAddressDetails.subUrb'
                              )?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="travelerDetailsForm.get('contactAndAddressDetails.subUrb')?.errors?.['required']"
                            >
                              {{ errorMessages['suburb']?.['required'] }}
                            </span>
                            <span
                              *ngIf="travelerDetailsForm.get('contactAndAddressDetails.subUrb')?.errors?.['leadingSpace']"
                            >
                              {{ errorMessages['suburb']?.['leadingSpace'] }}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                      <div class="col-md-3 col-12">
                        <label>State</label>
                        <select
                          id="state"
                          class="form-select"
                          id="australiaStates"
                          formControlName="state"
                          (change)="onChangeState($event)"
                        >
                          <option value="NSW" selected>NSW</option>
                          <option value="VIC">VIC</option>
                          <option value="QLD">QLD</option>
                          <option value="WA">WA</option>
                          <option value="SA">SA</option>
                          <option value="TAS">TAS</option>
                          <option value="NT">NT</option>
                          <option value="ACT">ACT</option>
                        </select>
                      </div>
                      <div class="col-md-3 col-12">
                        <label>Postal Code*</label>
                        <input
                          id="postalCode"
                          class="form-control"
                          pInputText
                          formControlName="postalCode"
                          pKeyFilter="int"
                          placeholder="Enter postal code"
                          aria-label="default input example"
                          maxlength="4"
                          (blur)="onBlurPostCode()"
                        />
                        <ng-container
                          *ngIf="
                            travelerDetailsForm.get(
                              'contactAndAddressDetails.postalCode'
                            )?.invalid &&
                            (travelerDetailsForm.get(
                              'contactAndAddressDetails.postalCode'
                            )?.dirty ||
                              travelerDetailsForm.get(
                                'contactAndAddressDetails.postalCode'
                              )?.touched)
                          "
                        >
                          <small class="text-danger">
                            <span
                              *ngIf="travelerDetailsForm.get('contactAndAddressDetails.postalCode')?.errors?.['required']"
                            >
                              {{ errorMessages['postal_code']?.['required'] }}
                            </span>
                            <span
                              *ngIf="travelerDetailsForm.get('contactAndAddressDetails.postalCode')?.errors?.['invalidPostCodeForState']"
                            >
                              {{ travelerDetailsForm.get('contactAndAddressDetails.postalCode')?.errors?.['invalidPostCodeForState']}}
                            </span>
                          </small>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-5 justify-content-start">
          <div class="col-md-10 col-12 p-md-5 p-3 pt-3">
            <div class="align-items-center justify-content-center flexgrid">
              <div class="row">
                <div class="col-md-6 col-12 text-center">
                  <a
                    class="button btn btn-outline-info rounded-pill p-1 px-2 save-quote"
                    (click)="
                      openSaveAndSendModal();
                      triggerSegmentEvent('saveEmailMain')
                    "
                    ><span class="me-1">Save & Email Quote</span>
                    <!-- <i class="fa-regular fa-envelope"></i> -->
                    <img
                      src="assets/icons/ract/envelope.svg"
                      alt=""
                      width="24"
                    />
                  </a>
                  <a
                    (click)="
                      downloadPDS(); triggerSegmentEvent('downloadPdsMain')
                    "
                    class="button btn btn-outline-info rounded-pill p-1 px-2 download-quote"
                    ><span id="downloadPDSButton" class="me-1"
                      >Download PDS</span
                    >
                    <!-- <i class="fa-regular fa-envelope"></i> -->
                    <img
                      src="assets/icons/ract/download.svg"
                      alt=""
                      width="24"
                    />
                  </a>
                  <a
                    class="button btn btn-outline-info rounded-pill me-2 ms-1"
                    (click)="
                      openSaveAndSendModal();
                      triggerSegmentEvent('saveEmailSideIcon')
                    "
                    >Save & Email Quote</a
                  >
                  <a
                    (click)="
                      downloadPDS(); triggerSegmentEvent('downloadPdslSideIcon')
                    "
                    class="button btn btn-outline-info rounded-pill"
                    >Download PDS</a
                  >
                </div>
                <div class="col-md-7 col-12 px-4">
                  <p class="mt-4 mt-md-1 mb-0" *ngIf="activatedClub === 'W2C'">
                    <strong>Need help? Call us on:</strong>
                    <strong class="number text-md-end">
                      <a class="text-gray" href="tel:+61292257599">
                        +61 2 9225 7599</a
                      >
                    </strong>
                  </p>
                  <p class="mt-4 mt-md-1 mb-0" *ngIf="activatedClub === 'RACV'">
                    <strong>Need help? Call us on:</strong>
                    <strong class="number text-md-end">
                      <a class="text-gray" href="tel:+13 13 29"> 13 13 29</a>
                    </strong>
                  </p>
                  <p class="mt-4 mt-md-1 mb-0" *ngIf="activatedClub === 'RAC'">
                    <strong>Need help? Call us on:</strong>
                    <strong class="number text-md-end">
                      <a class="text-gray" href="tel:+ 1 300 655 179">
                        1 300 655 179</a
                      >
                    </strong>
                  </p>
                  <p class="mt-4 mt-md-1 mb-0" *ngIf="activatedClub === 'RACQ'">
                    <strong>Need help? Call us on:</strong>
                    <strong class="number text-md-end">
                      <a class="text-gray" href="tel:+61 1300 338 821">
                        1300 338 821</a
                      >
                    </strong>
                  </p>
                  <p class="mt-4 mt-md-1 mb-0" *ngIf="activatedClub === 'AANT'">
                    <strong>Need help? Call us on:</strong>
                    <strong class="number text-md-end">
                      <a class="text-gray" href="tel:+(08) 8925 5901 ">
                        (08) 8925 5901</a
                      >
                    </strong>
                  </p>
                  <p class="mt-4 mt-md-1 mb-0" *ngIf="activatedClub === 'RAA'">
                    <strong>Need help? Call us on:</strong>
                    <strong class="number text-md-end">
                      <a class="text-gray" href="tel:+61882024346 ">
                        +61 8 8202 4346
                      </a>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>

  <app-email-quote #saveAndSendEmail></app-email-quote>
  <!-- OFFCANVAS -->

  <div
    id="bottom-bar"
    class="container-fluid pt-md-3 pb-md-3 pt-2 pb-2 fixed-bottom"
  >
    <div class="row justify-content-center">
      <div class="col-md-10 col-12">
        <div class="row">
          <div class="col-12 text-center d-md-none">
            <a
              href="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom"
              class="text-decoration-none"
              ><img src="assets/images/ract/uparrow.png" style="width: 18px" />
              View full price breakdown</a
            >
          </div>
          <div class="col-sm-3 col-6 text-m-center">
            <label class="coverselections">Selected plan</label>
            <h2>{{ selectedCover?.selectedCoverTitle }}</h2>
          </div>
          <div class="col-md-2 col-6 text-m-center">
            <label class="coverselections">Total Cost</label>
            <h2>${{ quoteDetail?.premiums?.totalCost | number : '1.2-2' }}</h2>
          </div>
          <div class="col-md-3 col-12 text-center d-none d-md-block">
            <a
              href="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom"
              ><img src="assets/images/ract/uparrow.png" class=""
            /></a>
            <p
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom"
            >
              View full price breakdown
            </p>
          </div>

          <div class="col-md-4 col-12 text-end text-m-center">
            <button
              class="btn btn-outline-info rounded-pill btn-sm"
              (click)="redirectToProductPage()"
            >
              <i class="fa-solid fa-chevron-left"></i>
              <span class="d-none d-md-inline-block">&nbsp;Back</span>
            </button>
            <span
              [ngClass]="!isEnableForm ? 'disabledcontent' : ''"
              id="discon5"
            >
              <button
                class="btn btn-info rounded-pill btn-sm px-4 px-lg-auto"
                (click)="redirectToTravelerDetails()"
                [disabled]="
                  primaryReassessment ||
                  secondaryReassessment ||
                  dependentReassessment ||
                  isButtonDisabled
                "
              >
                Confirm & Finalise Quote
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer-price-breakdown
    [selectedCover]="selectedCover"
    [quoteEnquiry]="quoteEnquiry"
    [quoteDetail]="quoteDetail"
    [step]="'step2'"
    [submitBtnLabel]="'Confirm & Finalise Quote'"
    (onSubmitBtnClick)="redirectToTravelerDetails()"
    [enableSubmitBtn]="isEnableForm"
  ></app-footer-price-breakdown>

  <!-- Offcanvas For Start Medical Assesment -->
  <div
    class="offcanvas offcanvas-end medical-canvas medmob"
    tabindex="-1"
    id="medical_btn"
    aria-labelledby=""
    style="width: 50%; overflow-y: auto"
  >
    <div class="offcanvas-body">
      <!-- Start Medical Assessment 01 -->
      <form [formGroup]="medicalDeclarationForm">
        <div class="card border-0 p-md-3" id="medical_01">
          <img
            src="assets/icons/ract/cancel.png"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            class="offcmedaccess"
          />

          <h4 id="offcanvasRightLabel">
            Assessment for {{ selectedAssessmentDetails?.firstName }}
            {{ selectedAssessmentDetails?.lastName }}
          </h4>
          <p>
            Please answer all of the following questions on behalf of yourself
            and any persons you declare for the purposes of being listed on your
            policy.
          </p>
          <ng-container *ngIf="getSpecificQuestion(1)">
            <div class="mb-3">
              <p>
                {{ getSpecificQuestion(1) }}
              </p>
              <input
                type="radio"
                class="btn-check"
                name="medicalTreatment"
                formControlName="medicalTreatment"
                id="medical_treatment_yes"
                value="yes"
                data-bs-toggle="modal"
                data-bs-target="#medical_treatmentModal"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="medical_treatment_yes"
                >Yes</label
              >

              <input
                type="radio"
                class="btn-check"
                name="medicalTreatment"
                formControlName="medicalTreatment"
                id="medical_treatment_no"
                value="no"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="medical_treatment_no"
                >No</label
              >
            </div>
          </ng-container>
          <ng-container *ngIf="getSpecificQuestion(2)">
            <div class="mb-3">
              <p>
                {{ getSpecificQuestion(2) }}
              </p>
              <input
                type="radio"
                class="btn-check"
                name="investigation"
                formControlName="investigation"
                id="investigation_yes"
                value="yes"
                data-bs-toggle="modal"
                data-bs-target="#investigationModal"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="investigation_yes"
                >Yes</label
              >

              <input
                type="radio"
                class="btn-check"
                name="investigation"
                formControlName="investigation"
                id="investigation_no"
                value="no"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="investigation_no"
                >No</label
              >
            </div>
          </ng-container>
          <ng-container *ngIf="getSpecificQuestion(3)">
            <div class="mb-3">
              <p>
                {{ getSpecificQuestion(3) }}
              </p>
              <input
                type="radio"
                class="btn-check"
                name="purchasePolicy"
                formControlName="purchasePolicy"
                id="purchase_policy_yes"
                value="yes"
                data-bs-toggle="modal"
                data-bs-target="#purchase_policyModal"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="purchase_policy_yes"
                >Yes</label
              >

              <input
                type="radio"
                class="btn-check"
                name="purchasePolicy"
                formControlName="purchasePolicy"
                id="purchase_policy_no"
                value="no"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="purchase_policy_no"
                >No</label
              >
            </div>
          </ng-container>
          <ng-container *ngIf="getSpecificQuestion(4)">
            <div class="mb-3">
              <p>
                {{ getSpecificQuestion(4) }}
              </p>
              <input
                type="radio"
                class="btn-check"
                name="medicalCondition"
                formControlName="medicalCondition"
                id="medical_condition_yes"
                value="yes"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="medical_condition_yes"
                >Yes</label
              >

              <input
                type="radio"
                class="btn-check"
                name="medicalCondition"
                formControlName="medicalCondition"
                id="medical_condition_no"
                value="no"
                data-bs-toggle="modal"
                data-bs-target="#medical_conditionModal"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="medical_condition_no"
                >No</label
              >
            </div>
          </ng-container>
          <!-- TO DO: UPDATE THE 5TH QUESTION WITH ITS CORRESPONDING PROPERTIES -->
          <ng-container *ngIf="getSpecificQuestion(5)">
            <div class="mb-3">
              <p>
                {{ getSpecificQuestion(5) }}
              </p>
              <input
                type="radio"
                class="btn-check"
                name="toUpdateRacvName"
                formControlName="toUpdateRacvName"
                id="medical_condition_yes"
                value="yes"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="medical_condition_yes"
                >Yes</label
              >

              <input
                type="radio"
                class="btn-check"
                name="toUpdateRacvName"
                formControlName="toUpdateRacvName"
                id="medical_condition_no"
                value="no"
              />
              <label
                class="btn btn-outline-info rounded-pill px-5"
                for="medical_condition_no"
                >No</label
              >
            </div>
          </ng-container>
          <div class="text-end">
            <button
              type="button"
              class="btn btn-outline-info rounded-pill px-4 py-2 text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-info rounded-pill px-4 py-2 medical-view"
              data-view-id="1"
              data-bs-dismiss="offcanvas"
              [disabled]="!isQuestionnaireContinue"
              (click)="openDialog()"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- 38 Medical Conditions COVID-->
  <div
    class="modal fade"
    id="staticBackdrop"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content existing-medical-conditions">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel"></h5>
          <img
            src="assets/icons/ract/cancel.png"
            class=""
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body ps-5 pe-5">
          <ol class="threecol">
            <li>acne</li>
            <li>
              allergies limited to Rhinitis, Chronic Sinusitis, Eczema, Food
              Intolerance, Hay Fever, however this excludes any Anaphylaxis as
              part of any such condition
            </li>
            <li>
              asthma providing you: have no other lung disease, and you are less
              than 60 years of age at the time you purchased the policy.
            </li>
            <li>bell's palsy</li>
            <li>benign positional vertigo</li>
            <li>bunions</li>
            <li>carpal tunnel syndrome</li>
            <li>cataracts</li>
            <li>coeliac disease</li>
            <li>congenital blindness</li>
            <li>congenital deafness</li>
            <li>
              diabetes type I providing you: were diagnosed over 24 months ago;
              and have no eye, kidney, nerve, or vascular complications; and do
              not also suffer from a known cardiovascular disease, hypertension,
              or <span>hypercholesterolaemia</span>; and are under 50 years of
              age at the date of policy purchase
            </li>

            <li>
              diabetes type II providing you: were diagnosed over 24 months ago;
              and have no eye, kidney, nerve, or vascular complications; and do
              not also suffer from a known cardiovascular disease, hypertension,
              or <span>hypercholesterolaemia</span>; and are under 50 years of
              age at the date of policy purchase
            </li>
            <li>dry eye syndrome</li>
            <li>
              epilepsy providing: there has been no change to your medication
              regime in the last 24 months; and you are not on more than one
              anticonvulsant medication
            </li>
            <li>gastric reflux</li>
            <li>gastric/peptic ulcer</li>
            <li>glaucoma</li>
            <li>gout</li>
            <li>graves' disease</li>
            <li>hiatus hernia</li>
            <li>
              hip/knee replacement if performed more than 24 months ago but less
              than 10 years ago
            </li>
            <li>
              <span>hyperlipidaemia&nbsp;</span>(high blood lipids) providing
              you do not also suffer from a known cardiovascular disease and/or
              diabetes
            </li>

            <li>
              hypertension (high blood pressure) providing you do not also
              suffer from a known cardiovascular disease and/or diabetes
            </li>
            <li>
              <span>hypercholesterolaemia&nbsp;</span>(high cholesterol)
              providing you do not also suffer from a known cardiovascular
              disease and/or diabetes
            </li>
            <li>hypothyroidism, including hashimoto's disease</li>
            <li>incontinence</li>
            <li>insulin resistance</li>
            <li>macular degeneration</li>
            <li>meniere's disease</li>
            <li>migraine</li>
            <li>nocturnal cramps</li>
            <li>plantar fasciitis</li>
            <li>raynaud's disease</li>
            <li>sleep apnoea</li>
            <li>solar keratosis</li>
            <li>trigeminal neuralgia</li>
            <li>trigger finger</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <!-- Extra Modal of Medical Assessment 01  -->
  <div
    class="modal fade"
    id="medical_treatmentModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body px-4">
          <p>
            We cannot offer any travel insurance cover on this policy as you
            have declared a traveller that is either: travelling against medical
            advice; or travelling for medical treatment.
          </p>
          <p>
            Please get in touch on
            <span class="fw-bold">13 13 19 </span>if you have any questions.
          </p>
          <div class="text-end">
            <button
              class="btn btn-outline-info rounded-pill px-4 me-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              class="btn btn-info rounded-pill px-4"
              data-bs-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 2nd Question -->
  <div
    class="modal fade"
    id="investigationModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body px-4">
          <p>
            By answering yes to this question you understand that no cover is
            available for any condition or related condition where you are
            awaiting surgery, treatment, investigation or procedures. Please
            contact us on <span class="fw-bold"> 13 13 19</span> if you would
            like to discuss this further or require clarification on this
            question.
          </p>

          <div class="text-end">
            <button
              class="btn btn-outline-info rounded-pill px-4 me-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              class="btn btn-info rounded-pill px-4"
              data-bs-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 3rd Question -->
  <div
    class="modal fade"
    id="purchase_policyModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body px-4">
          <p>
            By answering yes to this question you understand that you must hit
            the continue button below and complete a medical assessment to
            determine if you are eligible to have cover for any of these
            conditions.
          </p>
          <div class="text-end">
            <button
              class="btn btn-outline-info rounded-pill px-4 me-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              class="btn btn-info rounded-pill px-4"
              data-bs-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 4rd Question -->
  <div
    class="modal fade"
    id="medical_conditionModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body px-4">
          <p>
            You cannot proceed with the quotation until you acknowledge that any
            medical condition not declared will result in having no cover for
            any claims arising from, related to, or made worse by that medical
            conditions.
          </p>
          <div class="text-end">
            <button
              class="btn btn-outline-info rounded-pill px-4 me-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              class="btn btn-info rounded-pill px-4"
              data-bs-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-toast></p-toast>
  <!-- Accept / Deny additional premium  -->
  <p-dialog
    header="Additional Premium Confirmation"
    [(visible)]="showAcceptMedicalAssessmentModal"
    [modal]="true"
    [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [closeOnEscape]="false"
    [closable]="false"
    [focusOnShow]="false"
  >
    <ng-template pTemplate="header">
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <span class="font-bold white-space-nowrap">{{
          currentAssessmentName
        }}</span>
      </div>
    </ng-template>
    <div>
      <ul>
        <li *ngFor="let declaration of medicalConditions">
          {{ declaration }}
        </li>
      </ul>
    </div>
    <div
      *ngIf="
        medicalPremium?.totalAdditionalPremiumAmount > 0 &&
        medicalPremium?.isNotCovered === false
      "
    >
      <p class="m-0">
        Please indicate whether or not you agree to pay the additional premium
        to cover these condition.
      </p>
      <br />
      <strong
        >Additional premium: ${{
          medicalPremium?.totalAdditionalPremiumAmount | number : '1.2-2'
        }}</strong
      >&nbsp;<span>after taxes and discounts</span> <br /><br />
    </div>
    <div
      *ngIf="
        medicalPremium?.totalAdditionalPremiumAmount == 0 &&
        medicalPremium?.isNotCovered === false
      "
    >
      <p class="m-0">
        There is no additional premium payable for the medical conditions
        declared.
      </p>
    </div>
    <div *ngIf="medicalPremium?.isNotCovered === false">
      <p class="m-0">
        Please note, that if you Decline and choose to proceed with the travel
        insurance, you will not be covered for any event arising from or related
        to this medical condition.
      </p>
    </div>
    <div
      *ngIf="
        medicalPremium?.totalAdditionalPremiumAmount == 0 &&
        medicalPremium?.isNotCovered === true &&
        medicalPremium?.medicalConditions === ''
      "
    >
      <p class="m-0">
        Please select either option to continue. By selecting a ‘Update Medical
        Assessment’ , you are required to answer a pre-medical assessment
        questionnaire.
      </p>
    </div>
    <div
      *ngIf="
        medicalPremium?.totalAdditionalPremiumAmount == 0 &&
        medicalPremium?.isNotCovered === true &&
        medicalPremium?.medicalConditions !== ''
      "
    >
      <p class="m-0">
        Unfortunately the Medical Condition(s) you have declared cannot be
        covered. If you choose to purchase this policy, you will not be covered
        for any event arising from or related to this Medical Condition(s).
      </p>
    </div>
    <ng-template pTemplate="footer">
      <button
        *ngIf="medicalPremium?.isNotCovered === false"
        (click)="acceptMedical('Accepted')"
        class="btn btn-info rounded-pill mobhalf"
      >
        Accept
      </button>
      &nbsp;
      <button
        *ngIf="medicalPremium?.isNotCovered === false"
        (click)="acceptMedical('Declined')"
        class="btn btn-danger px-4 rounded-pill mobhalf"
        pButton
        pTooltip="Please note, that if you choose to proceed with the travel insurance, you will not be covered for any event arising from or related to this medical condition."
        tooltipPosition="top"
        tooltipStyleClass="custom-tooltip-declined"
      >
        Decline
      </button>
      &nbsp;
      <button
        (click)="reOpenMedicalDeclaration()"
        class="btn btn-outline-info rounded-pill mobfull"
      >
        Update Medical Assessment
      </button>
      <button
        *ngIf="medicalPremium?.isNotCovered === true"
        (click)="notCoveredMedical(medicalPremium.medicalConditions)"
        class="btn btn-info rounded-pill mt-2"
      >
        OK
      </button>
    </ng-template>
  </p-dialog>

  <!-- Medical Conditions definition -->
  <div
    class="modal fade"
    id="existingMedConditionDef"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="existingMedConditionDefLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="existingMedConditionDefLabel">
            Medical Condition
          </h5>
          <img
            src="assets/icons/ract/cancel.png"
            class=""
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div class="modal-body ps-5 pe-5 medical-condition-mobile">
          <p>
            A
            <strong><em>Medical Condition</em></strong>
            means any medical or physical condition, disorder, disease,
            disability or illness, including any
            <strong><em>Mental Illness</em></strong
            >, which at the <strong><em>Relevant Time, You</em></strong> were
            aware of, or a reasonable person in the circumstances could be
            expected to have been aware of, and at the
            <strong><em>Relevant Time</em></strong
            >:
          </p>
          <ul class="numbered-list">
            <li>
              is chronic, ongoing, terminal, or has affected or involved one or
              more of the following:
              <ul class="alphabet-list">
                <li>
                  heart, circulatory system, lungs or respiratory system, brain,
                  kidneys, liver, or cancer;
                </li>
                <li>
                  surgery involving the back, neck, joints, or abdomen; or
                </li>
              </ul>
            </li>
            <li>
              in the last 24 months had:
              <ul class="alphabet-list">
                <li>
                  presented symptoms which would have caused an ordinarily
                  prudent person to seek medical opinion or treatment;
                </li>
                <li>become exacerbated or complicated; or</li>
                <li>
                  been diagnosed, treated or treatment was recommended by a
                  <strong><em>Treating Doctor</em></strong
                  >.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- remove assessment dependent dialog-->
  <p-confirmDialog />

  <p-dialog
    header="Alert"
    [(visible)]="showReassessAlert"
    [modal]="true"
    [closable]="false"
    [dismissableMask]="false"
  >
    <p class="text-danger">
      The change made in your policy has resulted in the recalculation of the
      medical premium. Please re-assess the medical condition(s).
    </p>
    <p-footer>
      <button
        type="button"
        pButton
        label="Ok"
        (click)="hideReassessAlert()"
      ></button>
    </p-footer>
  </p-dialog>
  <app-racv-footer></app-racv-footer>
</div>
