import { InitialQuoteRequest } from './../../interfaces/quote';
import {
  AfterViewChecked,
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RouterLink, Router } from '@angular/router';
import {
  Subject,
  Subscription,
  firstValueFrom,
  takeUntil,
  catchError,
  of,
} from 'rxjs';
import moment from 'moment';
import { NgSelectModule } from '@ng-select/ng-select';

import { RacvHeaderComponent } from '../../components/racv-header/racv-header.component';
import { RacvFooterComponent } from '../../components/racv-footer/racv-footer.component';
import { StepBarComponent } from '../../components/step-bar/step-bar.component';
import { FooterPriceBreakdownComponent } from '../../components/footer-price-breakdown/footer-price-breakdown.component';
import { ChooseYourOptionComponent } from '../../components/choose-your-option/choose-your-option.component';

import { Country } from '../../interfaces/country';
import { Rule } from '../../interfaces/rule';

import { CountryService } from '../../services/country.service';
import { PricingService } from '../../services/pricing.service';
import { LocalCacheService } from '../../services/localCache.service';
import { QuoteService } from '../../services/quote.service';
import { WordingService } from '../../services/wording.service';
import { ValidationService } from '../../services/validation.service';
import { GlobalService } from '../../services/global.service';
import { PDSService } from '../../services/pds.service';

import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { environment } from '../../../environments/environment';
import { Utility } from '../../utils/utils';

import { stateList } from '../../utils/stateList';
import { KeyFilterModule } from 'primeng/keyfilter';
import _ from 'lodash';
import { EmailQuoteComponent } from '../../components/email-quote/email-quote.component';
import { SegmentService } from '../../services/segment.service';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-step-one',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    RacvHeaderComponent,
    RacvFooterComponent,
    TabViewModule,
    RadioButtonModule,
    ReactiveFormsModule,
    TableModule,
    AccordionModule,
    ButtonModule,
    StepBarComponent,
    FooterPriceBreakdownComponent,
    OverlayPanelModule,
    CalendarModule,
    ChooseYourOptionComponent,
    MultiSelectModule,
    KeyFilterModule,
    NgSelectModule,
    EmailQuoteComponent,
  ],
  templateUrl: './step-one.component.html',
  styleUrl: './step-one.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StepOneComponent
  implements AfterViewInit, OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild('toggleIcon', { static: false }) toggleIcon!: ElementRef;
  @ViewChild('proceedBtn', { static: true }) proceedBtn!: ElementRef;
  private destroySub$ = new Subject<void>();
  quoteEnquiry: any;
  allBenefits: any[] = [];
  country: Country | undefined;
  selectedTabIndex: number = 0;
  selectedTripValue!: string;
  selectedTripLabel!: string;
  rangeDates: Date[] | undefined;
  returnDateAMT: any;
  selectedTripLenghtValue!: string;
  selectedTripLenghtLabel!: string;
  displayDepartDate!: string;
  displayReturnDate!: string;
  departDate!: string;
  returnDate!: string;
  groupedCountries: any[] = [];
  basicPrice: any = {};
  essentialPrice: any = {};
  topPrice: any = {};
  annualPrice: any = {};
  domesticPrice: any = {};
  quoteDetail: any;
  selectedCover: any = {};
  pricingDetail: any[] = [];
  isDomestic: boolean = false;
  alignment: 'left' | 'center' | 'right' = 'left';
  currentDate: Date = new Date();
  updateQuoteForm: FormGroup = new FormGroup({});
  getaquotewording: string | undefined;
  activatedClub: string = '';
  errorMessages: any = {};
  validationsList: any[] = [];
  subs: Subscription[] = [];
  primaryAgeMessage: string = '';
  secondaryAgeMessage: string = '';
  @ViewChild('ms') multiselect!: MultiSelect;
  tabs: any[] = [];
  isMostPopular: boolean = false;
  isTier1: boolean = false;
  isTier2: boolean = false;
  isTier3: boolean = false;
  countriesNotAllowedToTravel: any = [];
  selectedCountries: any = [];
  selectedMemberValue!: string;
  selectedMemberLabel!: boolean;
  @ViewChild('travelDestination') travelDestinationPopup!: OverlayPanel;
  @ViewChild('travelDestinationBtn') travelDestinationBtn!: ElementRef;
  @ViewChild('departReturnDate') departReturnDatePopup!: OverlayPanel;
  @ViewChild('departReturnDateBtn') departReturnDateBtn!: ElementRef;
  @ViewChild('travellers') travellersPopup!: OverlayPanel;
  @ViewChild('travellersBtn') travellersBtn!: ElementRef;
  allTopSixBenefits: any[] = [];
  currentTier!: string;
  singleTripForm: FormGroup = new FormGroup({});
  multiTripForm: FormGroup = new FormGroup({});
  showDiscountedPrice: boolean = false;
  maxDepartDate: Date = new Date();
  standardPriceLabel: string = '';
  discountedPriceLabel: string = '';
  isCruiseCountry: any;
  displayDefaultSingleDate: boolean = false;
  selectedMobileTripType: string = '';
  isButtonDisabled: boolean = false;

  firstMinAge: number = 0;
  firstMaxAge: number = 0;
  secondMinAge: number = 0;
  secondMaxAge: number = 0;

  firstMultiMinAge: number = 0;
  firstMultiMaxAge: number = 0;
  secondMultiMinAge: number = 0;
  secondMultiMaxAge: number = 0;
  isSki: boolean = true;
  isMobile: boolean = false;
  hasQuoteFormValueChange: boolean = false;
  initialQuoteFormValues: any;

  allCountries: any[] = [];
  ngSelectIsFocused: boolean = false;
  isAgeChanged: boolean = false;
  defaultReturnDateValueIncrement: number = 0;
  resumeResponse: any;
  quoteExpired: boolean = false;
  isMembershipRemoved: boolean = false;
  searchTerms: any;
  filteredCountries = [...this.allCountries];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private countryService: CountryService,
    private location: Location,
    private pricingService: PricingService,
    private localCacheService: LocalCacheService,
    private quoteService: QuoteService,
    private _formBuilder: FormBuilder,
    private wordingService: WordingService,
    private validationService: ValidationService,
    public globalService: GlobalService,
    private cdRef: ChangeDetectorRef,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private pdsService: PDSService,
    private segmentService: SegmentService,
  ) {
    //to initilize the control form name
    this.updateQuoteForm = this._formBuilder.group({
      travelDestination: [],
      rangeDates: [],
      age1: [],
      age2: [],
      dependentsCount: [],
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 820;
  }

  @ViewChild('saveAndSendEmail')
  saveAndSendEmail!: EmailQuoteComponent;

  openSaveAndSendModal() {
    if (this.hasQuoteFormValueChange) {
      const modalElement = document.getElementById('updatequote');
      if (modalElement) {
        const modal = new Modal(modalElement);
        modal.show();
      }
    } else {
      this.saveAndSendEmail?.openModal();
    }
  }

  ngAfterViewInit() {
    this.initializeMobileScripts();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroySub$.next();
    this.destroySub$.complete();
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  async ngOnInit() {
    this.isMobile = window.innerWidth < 820;
    let emailquoteid =
      this.globalService.getQueryParamInfo('emailquoteid') ||
      this.globalService.getQueryParamInfo('QuoteGuid');
    let ctmToken = this.globalService.getQueryParamInfo('CTMToken');
    let finderToken = this.globalService.getQueryParamInfo('FinderToken');
    if (emailquoteid || ctmToken || finderToken) {
      await this.resumeSubmission(emailquoteid);
    } else {
      if (!this.globalService.checkAndValidatePage(1, false)) {
        this.router.navigate(['/error']);
        return;
      }
    }
    const quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry') || {};
    this.selectedCountries = quoteEnquiry.travelDestination;
    const isCruiseCountry = this.selectedCountries.find(
      (item: any) => item.countryCode === 'AUC',
    );
    this.isCruiseCountry = isCruiseCountry ? true : false;

    this.activatedClub = environment.autoClub; //TO DO: CHANGE TO DYNAMIC HANDLING
    this.getAllBenefit();
    this.getAgeForIsSki(quoteEnquiry);
    this.quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry') || {};
    this.quoteDetail =
      this.localCacheService.getSessionStorage('quoteDetail') || {};
    if (!this.quoteDetail || Object.keys(this.quoteDetail).length === 0) {
      this.router.navigate(['/']);
      return;
    }
    this.pricingDetail =
      this.localCacheService.getSessionStorage('pricingDetail') || [];
    this.isAgeChanged =
      this.localCacheService.getSessionStorage('ageChanged') || '';
    this.isMembershipRemoved =
      this.localCacheService.getSessionStorage('membershipRemoved') || '';
    if (this.quoteEnquiry) {
      this.showDiscountedPrice =
        this.quoteEnquiry.isClubMember === true ||
        this.quoteEnquiry.isClubMember === 'true' ||
        (this.quoteEnquiry.promoCode !== undefined &&
          this.quoteEnquiry.promoCode !== null &&
          this.quoteEnquiry.promoCode !== '');
      this.selectedMemberLabel =
        quoteEnquiry.isClubMember === true ||
        quoteEnquiry.isClubMember === 'true';
      if (
        this.pricingDetail.length === 0 ||
        this.isAgeChanged ||
        this.isMembershipRemoved
      ) {
        this.getPricing(this.quoteEnquiry);
        this.localCacheService.saveSessionStorage('ageChanged', false);
        this.localCacheService.saveSessionStorage('membershipRemoved', false);
      } else {
        this.selectedTabIndex =
          this.localCacheService.getSessionStorage('selectedTabIndex') || 0;
        this.selectedCover =
          this.localCacheService.getSessionStorage('selectedCoverDetail') || {};
        this.topPrice =
          this.localCacheService.getSessionStorage('topPrice') || {};
        this.essentialPrice =
          this.localCacheService.getSessionStorage('essentialPrice') || {};
        this.basicPrice =
          this.localCacheService.getSessionStorage('basicPrice') || {};
        this.annualPrice =
          this.localCacheService.getSessionStorage('annualPrice') || {};
        this.domesticPrice =
          this.localCacheService.getSessionStorage('domesticPrice') || {};
        this.selectedTripLenghtLabel =
          this.localCacheService.getSessionStorage('tripLenghtLabel') || '';
        this.isDomestic =
          this.localCacheService.getSessionStorage('domesticCountry') || '';

        if (this.selectedTabIndex === 0) {
          if (this.quoteEnquiry.isSingleTrip && this.isDomestic) {
            this.currentTier = 'Domestic';
          } else if (this.quoteEnquiry.isSingleTrip && !this.isDomestic) {
            this.currentTier = 'Tier3';
          } else if (!this.quoteEnquiry.isSingleTrip && this.isDomestic) {
            this.currentTier = 'Annual Multi Trip';
          } else {
            this.currentTier = 'Annual Multi Trip';
          }
        } else if (this.selectedTabIndex === 1) {
          if (this.quoteEnquiry.isSingleTrip && this.isDomestic) {
            this.currentTier = 'Cancellation';
          } else {
            this.currentTier = 'Tier2';
          }
        } else if (this.selectedTabIndex === 2) {
          if (this.quoteEnquiry.isSingleTrip && this.isDomestic) {
            this.currentTier = 'Car';
          } else {
            this.currentTier = 'Tier1';
          }
        }

        this.tabs = [];
        if (this.activatedClub === 'W2C') {
          this.pricingDetail.forEach((obj) => {
            if (obj.mostPopular) {
              this.isMostPopular = true;
            }
            if (obj.type === 'Tier3' && this.isDomestic) {
              this.tabs.push({
                type: obj.type,
                mostPopular: obj.mostPopular,
                price: this.domesticPrice,
              });
            } else if (obj.type === 'Tier3' && !this.isDomestic) {
              this.isTier3 = true;
              this.tabs.push({
                type: obj.type,
                mostPopular: obj.mostPopular,
                price: this.topPrice,
              });
            } else if (obj.type === 'Tier2') {
              this.isTier2 = true;
              this.tabs.push({
                type: obj.type,
                mostPopular: obj.mostPopular,
                price: this.essentialPrice,
              });
            } else if (obj.type === 'Tier1') {
              this.isTier1 = true;
              this.tabs.push({
                type: obj.type,
                mostPopular: obj.mostPopular,
                price: this.basicPrice,
              });
            } else if (obj.type === 'Annual Multi Trip') {
              this.tabs.push({
                type: obj.type,
                mostPopular: obj.mostPopular,
                price: this.annualPrice,
              });
            }
          });
        } else {
          this.pricingDetail.forEach((obj) => {
            if (obj.mostPopular) {
              this.isMostPopular = true;
            }
            if (obj.type === 'Tier3') {
              this.isTier3 = true;
              this.tabs.push({
                type: obj.type,
                mostPopular: obj.mostPopular,
                price: this.topPrice,
              });
            } else if (obj.type === 'Tier2') {
              this.isTier2 = true;
              this.tabs.push({
                type: obj.type,
                mostPopular: obj.mostPopular,
                price: this.essentialPrice,
              });
            } else if (obj.type === 'Tier1') {
              this.isTier1 = true;
              this.tabs.push({
                type: obj.type,
                mostPopular: obj.mostPopular,
                price: this.basicPrice,
              });
            } else if (obj.type === 'Annual Multi Trip') {
              this.tabs.push({
                type: obj.type,
                mostPopular: obj.mostPopular,
                price: this.annualPrice,
              });
            }
          });
        }

        if (this.tabs.length > 0) {
          this.tabs.sort((a, b) => {
            if (a.type > b.type) {
              return -1;
            } else if (a.type < b.type) {
              return 1;
            } else {
              return 0;
            }
          });
        }
      }
    }
    this.loadData(this.quoteEnquiry);
    this.loadValidations();
    this.countryService.getCountries().subscribe((response) => {
      this.allCountries = response;
      this.allCountries.sort((a, b) => {
        return a.countryName.localeCompare(b.countryName);
      });
    });
    this.setPriceLabels();
    const sydneyDate = new Date(
      new Intl.DateTimeFormat('en-US', { timeZone: 'Australia/Sydney' }).format(
        new Date(),
      ),
    );
    if (this.selectedTripLabel === 'Multiple') {
      this.maxDepartDate = new Date();
      this.maxDepartDate.setDate(sydneyDate.getDate() + 548);
    } else {
      this.maxDepartDate = new Date();
      this.maxDepartDate.setDate(sydneyDate.getDate() + 548);
      this.maxDepartDate.setFullYear(this.maxDepartDate.getFullYear() + 1);
    }
    this.cdRef.detectChanges();
    this.selectedMobileTripType = this.getMobileTripType();
    this.segmentService.segmentIdentifyIdWithQuoteId();
  }

  getAgeForIsSki(quote: any) {
    if (quote.age2) {
      if (quote.age2 > 85 || quote.age1 > 85) {
        this.isSki = false;
      } else {
        this.isSki = true;
      }
    } else {
      if (quote.age1 > 85) {
        this.isSki = false;
      } else {
        this.isSki = true;
      }
    }
  }

  initializeMobileScripts() {
    document.querySelectorAll('.btn-toggle-selector').forEach((element) => {
      element.addEventListener('click', () => {
        const accordionItem = element.parentElement as HTMLElement | null;
        if (accordionItem) {
          accordionItem.classList.toggle('active');
          document.querySelectorAll('.toggle-item').forEach((item) => {
            if (item !== accordionItem) {
              item.classList.remove('active');
            }
          });

          const toggleItemContent = accordionItem.querySelector(
            '.toggle-item-content',
          ) as HTMLElement | null;
          if (toggleItemContent) {
            if (
              toggleItemContent.style.display === 'none' ||
              toggleItemContent.style.display === ''
            ) {
              toggleItemContent.style.display = 'block';
            } else {
              toggleItemContent.style.display = 'none';
            }
          }

          document.querySelectorAll('.toggle-item').forEach((item) => {
            if (item !== accordionItem) {
              const content = item.querySelector(
                '.toggle-item-content',
              ) as HTMLElement | null;
              if (content) {
                content.style.display = 'none';
              }
            }
          });
        }
      });
    });
  }

  async resumeSubmission(emailquoteid: string): Promise<void> {
    try {
      this.localCacheService.clearAllSession();
      //Check Agent info from quuery param
      if (Utility.getUrlParameter('a')) {
        // Resume from PAS with agent guid
        emailquoteid = this.globalService.getQueryParamInfo('QuoteGuid');
        let agentGuid = this.globalService.getQueryParamInfo('AgentGuid');
        let branchGuid = this.globalService.getQueryParamInfo('BranchGuid');
        let agentName = this.globalService.getQueryParamInfo('AgentName');
        let agentBranchName =
          this.globalService.getQueryParamInfo('BranchName');

        if (agentGuid) {
          this.localCacheService.saveSessionStorage('agentGuid', agentGuid);
        }
        if (agentName) {
          this.localCacheService.saveSessionStorage('agentName', agentName);
        }
        if (branchGuid) {
          this.localCacheService.saveSessionStorage('branchGuid', branchGuid);
        }
      } else if (Utility.getUrlParameter('t')) {
        // Resume from CTM
        const ctmToken = this.globalService.getQueryParamInfo('CTMToken');
        this.resumeResponse = await firstValueFrom(
          this.quoteService.getCTMSubmission(ctmToken).pipe(
            catchError((error) => {
              // handle error
              console.log('resume getCTMSubmission error', error);
              //this.router.navigate(['/']);
              return of([]); // or return a fallback value
            }),
            takeUntil(this.destroySub$),
          ),
        );
      } else if (Utility.getUrlParameter('vf')) {
        // Resume from Finder
        const finderToken = this.globalService.getQueryParamInfo('FinderToken');
        this.resumeResponse = await firstValueFrom(
          this.quoteService.getFinderSubmission(finderToken).pipe(
            catchError((error) => {
              // handle error
              console.log('resume getFinderSubmission error', error);
              //this.router.navigate(['/']);
              return of([]); // or return a fallback value
            }),
            takeUntil(this.destroySub$),
          ),
        );
      }
      if (emailquoteid) {
        this.resumeResponse = await firstValueFrom(
          this.quoteService.getSubmission(emailquoteid).pipe(
            catchError((error) => {
              // handle error
              console.log('resume getSubmission error', error);
              //this.router.navigate(['/']);
              return of([]); // or return a fallback value
            }),
            takeUntil(this.destroySub$),
          ),
        );
        // Check for quote expiry and its status
        if (
          this.resumeResponse.policyStatus != 'P' &&
          this.resumeResponse.policyStatus != 'A' &&
          this.resumeResponse.policyStatus != 'N' &&
          this.resumeResponse.policyStatus != 'E'
        ) {
          this.router.navigate(['/error']);
        } else {
          if (this.resumeResponse.policyStatus === 'E') {
            this.quoteExpired = true;
            if (emailquoteid) {
              this.localCacheService.saveSessionStorage(
                'expiredQuoteId',
                emailquoteid,
              );
            }
          }
        }
      }
      const response = this.resumeResponse;
      if (response?.Success === false) {
        console.log('resume getSubmission response error');
      } else {
        var _travelDestination: any[] = [];

        var country: any = await firstValueFrom(
          this.countryService
            .groupCountriesByState('')
            .pipe(takeUntil(this.destroySub$)),
        );

        if (!country) {
          console.log('resume getCountry response error');
        } else {
          if (response.destinations) {
            response.destinations.forEach((element: any) => {
              country.forEach((_region: any) => {
                var _fetched = _region.countries.find((_country: any) =>
                  element.countryName
                    ? _country.countryCode == element.countryCode &&
                      _country.countryName == element.countryName
                    : _country.countryCode == element.countryCode,
                );
                if (_fetched) {
                  _travelDestination.push({
                    countryCode: _fetched.countryCode,
                    countryName: this.formatCase(_fetched.countryName),
                    allowTravel: _fetched.allowTravel,
                    hasCalamity: _fetched.hasCalamity,
                    isDomestic: _fetched.isDomestic,
                    isPASAMT: _fetched.isPASAMT,
                    ratingRegionName: _region.ratingRegionName,
                    isSanctioned: _fetched.isSanctioned,
                    helixScore: _fetched.helixScore,
                    regionId: _fetched.regionId,
                    sortOrder: _fetched.sortOrder,
                  });
                }
              });
            });
          }
        }

        let newQuoteObject = {
          travelDestination: _travelDestination,
          depart: Utility.toDate(response.fromDate),
          return: Utility.toDate(response.toDate),
          rateDate: Utility.toDate(response.pricingDate),
          age1: response.ages[0],
          age2:
            response.ages.length > 1
              ? response.ages[1] > 0
                ? response.ages[1]
                : ''
              : '',
          dependentsCount: response.dependentsCount,
          state: stateList.find((state) => state.id == response.stateId),
          isClubMember: response.isClubMember,
          promoCode: response.promoCode,
          tripType: response.isSingleTrip == true ? 'Single' : 'Multiple',
          isSingleTrip: response.isSingleTrip,
        };
        this.localCacheService.saveSessionStorage(
          'quoteEnquiry',
          newQuoteObject,
        );

        this.quoteEnquiry =
          this.localCacheService.getSessionStorage('quoteEnquiry') || {};
        this.selectedCountries = this.quoteEnquiry.travelDestination;
        const isCruiseCountry = this.selectedCountries.find(
          (item: any) => item.countryCode === 'AUC',
        );
        this.isCruiseCountry = isCruiseCountry ? true : false;
        let domesticCountry = this.selectedCountries.every(
          (country: any) => country.isDomestic === true,
        );
        this.isDomestic = domesticCountry;
        this.localCacheService.saveSessionStorage(
          'domesticCountry',
          domesticCountry,
        );
        this.localCacheService.saveSessionStorage(
          'isCruiseCountry',
          this.isCruiseCountry,
        );

        // to call the pricing api to initialize the card products values
        var pricingDetail = await firstValueFrom(
          this.pricingService
            .getInitialPricing(newQuoteObject)
            .pipe(takeUntil(this.destroySub$)),
        );
        this.localCacheService.saveSessionStorage(
          'pricingDetail',
          pricingDetail,
        );

        if (pricingDetail) {
          this.initializeOtherClubProductCards(pricingDetail);

          this.selectedCover =
            this.localCacheService.getSessionStorage('selectedCoverDetail') ||
            {};

          var selectedProduct = pricingDetail.find(
            (_product: any) => _product.type == response.quoteName,
          );
          //Skiping selection if no ProductGUID selected. Use Case: User clicked on "Get a Quote" and then went to PAS for resume
          if (selectedProduct) {
            var selectedOption = selectedProduct.products.find(
              (_option: any) =>
                _option.clubProductGuid == response.clubProductGuid,
            );

            // to show available excess option for selected product
            var excessValues = selectedProduct.products.map(
              (item: any) => item.excess,
            );
            this.selectedCover['excessValues'] = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });

            this.selectedCover['selectedCoverTitle'] = response.productAlias;

            // to show pricing based on pricing api instead of get submission response (because: on this screen we show only pricing api response and discard the medical premium)
            this.selectedCover['selectedPrice'] = {
              clubProductGuid: response.clubProductGuid,
              productAlias: response.productAlias,
              productName: response.quoteName,
              productType: response.productType,
              excess: response.excess,
              gst: response.premiums.gst,
              isCruise: response?.isCruise,
              isSki: response?.isSking,
              basePremium: selectedOption?.basePremium,
              excessPremium: selectedOption?.excessPremium,
              cruisePremium: selectedOption?.cruisePremium,
              skiingPremium: selectedOption?.skiingPremium,
              membershipDisc: selectedOption?.membershipDisc,
              multiTripLengthPremium: selectedOption?.multiTripLengthPremium,
              promoCodeDisc: selectedOption?.promoCodeDisc,
              stampDuty: selectedOption?.stampDuty,
              totalPremium: selectedOption?.totalPremium,
              regularPremium: selectedOption?.regularPremium,
            };

            // to make tab selection
            this.tabs = this.tabs.map((a) => {
              if (a.type == response.quoteName) {
                a.price = this.selectedCover;
              }
              return a;
            });

            if (this.tabs.length > 0) {
              this.tabs.sort((a, b) => {
                if (a.type > b.type) {
                  return -1;
                } else if (a.type < b.type) {
                  return 1;
                } else {
                  return 0;
                }
              });
            }

            if (!response.isSingleTrip) {
              this.selectedCover['selectedPrice'].multiTripLength =
                response.multiTripLength;
            }
            this.localCacheService.saveSessionStorage(
              'selectedCoverDetail',
              this.selectedCover,
            );
            this.localCacheService.saveSessionStorage(
              'tripLenghtLabel',
              response.multiTripLength,
            );

            this.selectedTabIndex =
              response.quoteName == 'Tier1'
                ? 2
                : response.quoteName == 'Tier2'
                ? 1
                : 0;
          }
          // to sync selection of excess/sking/cruse with its premium value on card
          var event = {
            excess: response.excess,
            isSki: response?.isSking,
            isCruise: response?.isCruise,
          };
          this.onOptionChange(event);

          this.localCacheService.saveSessionStorage(
            'selectedTabIndex',
            this.selectedTabIndex,
          );
          this.localCacheService.saveSessionStorage('quoteDetail', response);

          // build traveler details session and assessment details session
          if (response.travelers.length > 0) {
            let storedTravelerDetails = {} as any;
            let storedAssessmentDetails = {} as any;
            storedTravelerDetails.medical_con = 'no';
            response.travelers.forEach((trvl: any) => {
              if (trvl.medicalInfo?.xml) {
                storedTravelerDetails.medical_con = 'yes';
              }
              if (trvl.role == 'primary') {
                storedTravelerDetails.adult1 = {
                  firstName: trvl.firstName ?? '',
                  lastName: trvl.lastName ?? '',
                  dateOfBirth: trvl.dateOfBirth ?? '',
                  one_med_con: trvl.medicalInfo?.xml ? 'yes' : 'no',
                };

                storedTravelerDetails.contactAndAddressDetails = {
                  emailId: trvl?.email,
                  confEmailId: trvl.email ?? '',
                  phoneNumber: trvl.phone ?? '',
                  postalCode: trvl.address?.postalCode ?? '',
                  street: trvl.address?.address ?? '',
                  subUrb: trvl.address?.city ?? '',
                  state:
                    stateList.find((state) => state.id == trvl.address?.stateId)
                      ?.code ?? '',
                };

                if (trvl.medicalInfo?.xml) {
                  storedAssessmentDetails.primary = {
                    role: 'primary',
                    transactionId: response.quoteId,
                    travelerId: trvl.travelerId,
                    xmlResult: trvl.medicalInfo?.xml,
                    medicalPremium: {
                      totalAdditionalPremiumAmount:
                        trvl.medicalInfo?.medicalPremium,
                      isNotCovered:
                        trvl.medicalInfo?.status == 'Not Covered'
                          ? true
                          : false,
                      medicalConditions: trvl.medicalInfo?.medicalConditions,
                      transactionId: response.quoteId,
                      travelerId: trvl.travelerId,
                    },
                  };
                }
              }

              if (trvl.role == 'secondary') {
                storedTravelerDetails.adult2 = {
                  firstName: trvl.firstName ?? '',
                  lastName: trvl.lastName ?? '',
                  dateOfBirth: trvl.dateOfBirth ?? '',
                  two_med_con: trvl.medicalInfo?.xml ? 'yes' : 'no',
                };

                if (trvl.medicalInfo?.xml) {
                  storedAssessmentDetails.secondary = {
                    role: 'secondary',
                    transactionId: response.quoteId,
                    travelerId: trvl.travelerId,
                    xmlResult: trvl.medicalInfo?.xml,
                    medicalPremium: {
                      totalAdditionalPremiumAmount:
                        trvl.medicalInfo?.medicalPremium,
                      isNotCovered:
                        trvl.medicalInfo?.status == 'Not Covered'
                          ? true
                          : false,
                      medicalConditions: trvl.medicalInfo?.medicalConditions,
                      transactionId: response.quoteId,
                      travelerId: trvl.travelerId,
                    },
                  };
                }
              }

              if (trvl.role == 'dependent') {
                let _dep: any = {
                  firstName: trvl.firstName ?? '',
                  lastName: trvl.lastName ?? '',
                  dateOfBirth: trvl.dateOfBirth ?? '',
                  travelerId: trvl.travelerId,
                };

                //adding values for showing medical assessment for dependent
                _dep.assessmentStatus = trvl.medicalInfo?.status ?? '';
                _dep.hasAssessment = !_dep.assessmentStatus ? false : true;
                _dep.dependent_med_con = trvl.medicalInfo ? 'yes' : 'no';
                _dep.medConSelected = !_dep.assessmentStatus ? false : true;
                _dep.reassessment = _dep.rescore;

                storedTravelerDetails.dependents =
                  storedTravelerDetails.dependents
                    ? [...storedTravelerDetails.dependents, _dep]
                    : [_dep];

                if (trvl.medicalInfo?.xml) {
                  let _depAssessment = {
                    role: 'dependent',
                    transactionId: response.quoteId,
                    travelerId: trvl.travelerId,
                    xmlResult: trvl.medicalInfo?.xml,
                    medicalPremium: {
                      totalAdditionalPremiumAmount:
                        trvl.medicalInfo?.medicalPremium,
                      isNotCovered:
                        trvl.medicalInfo?.status == 'Not Covered'
                          ? true
                          : false,
                      medicalConditions: trvl.medicalInfo?.medicalConditions,
                      transactionId: response.quoteId,
                      travelerId: trvl.travelerId,
                    },
                  };
                  storedAssessmentDetails.dependent =
                    storedAssessmentDetails.dependent
                      ? [...storedAssessmentDetails.dependent, _depAssessment]
                      : [_depAssessment];
                }
              }
            });

            storedTravelerDetails.emergencyContact = {
              firstName: response.emergencyContact?.firstName ?? '',
              lastName: response.emergencyContact?.lastName ?? '',
              emailId: response.emergencyContact?.email ?? '',
              phoneNumber: response.emergencyContact?.phone ?? '',
            };
            this.localCacheService.saveSessionStorage(
              'travelerDetails',
              storedTravelerDetails,
            );
            this.localCacheService.saveSessionStorage(
              'finalTravelerDetails',
              storedTravelerDetails,
            );
            this.localCacheService.saveSessionStorage(
              'assessmentDetails',
              storedAssessmentDetails,
            );
            this.localCacheService.saveSessionStorage(
              'currentAssessment',
              'primary',
            );
            this.localCacheService.saveSessionStorage('dependentIndex', -1);

            this.localCacheService.saveSessionStorage('resumeInfoDob', {
              age1: newQuoteObject.age1,
              age2: newQuoteObject.age2,
              dob1: storedTravelerDetails?.adult1?.dateOfBirth || '',
              dob2: storedTravelerDetails?.adult2?.dateOfBirth || ''
            });
          }
        } else {
          console.log(
            'resume getSubmission subscription error',
            'pricing api failed',
          );
          throw 'pricing api failed';
        }
      }
    } catch (error) {
      console.log('resume getSubmission subscription error', error);
      throw error;
    }
  }

  patchForm() {
    this.singleTripForm.patchValue({
      travelDestination: this.selectedCountries,
    });
    this.multiTripForm.patchValue({
      travelDestination: this.selectedCountries,
    });
  }

  triggerToggleClick() {
    if (this.toggleIcon) {
      this.renderer.selectRootElement(this.toggleIcon.nativeElement).click();
    }
  }

  toggleCollapse(event: Event, index: number): void {
    const button = event.target as HTMLElement;
    const element = document.getElementById('row_' + index);
    if (element) {
      const isExpanded = element.classList.contains('show');
      element.classList.toggle('show', !isExpanded);
      button.parentElement?.classList.toggle('collapsed', isExpanded);
      button.parentElement?.setAttribute(
        'aria-expanded',
        (!isExpanded).toString(),
      );
    }
  }

  toggleCollapseMobile(event: Event, index: number): void {
    const button = event.target as HTMLElement;
    const element = document.getElementById('mrow_' + index);
    if (element) {
      const isExpanded = element.classList.contains('show');
      element.classList.toggle('show', !isExpanded);
      button.parentElement?.classList.toggle('collapsed', isExpanded);
      button.parentElement?.setAttribute(
        'aria-expanded',
        (!isExpanded).toString(),
      );
    }
  }

  formatCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
  }
  loadData(quoteEnquiry: any) {
    this.selectedCountries = [];
    this.selectedTripLabel = quoteEnquiry.tripType;
    this.displayDepartDate = moment(quoteEnquiry.depart).format('DD/MM/YYYY');
    if (this.selectedTripLabel === 'Single') {
      this.rangeDates = [
        new Date(quoteEnquiry.depart),
        new Date(quoteEnquiry.return),
      ];
      this.displayReturnDate = moment(quoteEnquiry.return).format('DD/MM/YYYY');
    } else if (this.selectedTripLabel === 'Multiple') {
      let returnDateAMT = new Date(quoteEnquiry.depart);
      returnDateAMT = new Date(
        returnDateAMT.setFullYear(returnDateAMT.getFullYear() + 1),
      );
      returnDateAMT = new Date(
        returnDateAMT.setDate(returnDateAMT.getDate() - 1),
      );

      this.rangeDates = [new Date(quoteEnquiry.depart), returnDateAMT];
      this.displayReturnDate = moment(returnDateAMT).format('DD/MM/YYYY');
    }

    this.selectedCountries = quoteEnquiry?.travelDestination;
    const dependentCount =
      !quoteEnquiry?.dependentsCount ||
      quoteEnquiry?.dependentsCount === 0 ||
      quoteEnquiry?.dependentsCount === '0'
        ? ''
        : quoteEnquiry?.dependentsCount;
    this.updateQuoteForm = this._formBuilder.group(
      {
        travelDestination: [
          this.selectedCountries,
          [
            Validators.required,
            this.cannotTravelDomesticInternational(),
            this.cannotSelectDomesticAMTW2C(),
            this.cannotTravelValidator(),
          ],
        ],
        rangeDates: [this.rangeDates, [Validators.required]],
        age1: [quoteEnquiry?.age1 ?? '', [Validators.required]],
        age2: [quoteEnquiry?.age2 ?? '', []],
        dependentsCount: [dependentCount, []],
      },
      {
        validators: [
          this.datesNotEqualValidator('rangeDates'),
          // this.AMTReturnDateInvalid('rangeDates'),
          this.SingleTripReturnDateInvalid('rangeDates'),
          this.returnDateRequired('rangeDates'),
        ],
      },
    );

    //METHOD: STORE THE QUOTEFORM VALUES
    let quoteFormRawValues = this.updateQuoteForm.getRawValue();
    this.initialQuoteFormValues = {
      tripType: _.cloneDeep(this.selectedTripLabel),
      age1: _.cloneDeep(quoteFormRawValues?.age1),
      age2: _.cloneDeep(quoteFormRawValues?.age2),
      dependentsCount: _.cloneDeep(quoteFormRawValues?.dependentsCount),
      destinations: _.cloneDeep(quoteFormRawValues?.travelDestination),
      departDate: _.cloneDeep(this.displayDepartDate),
      returnDate: _.cloneDeep(this.displayReturnDate),
      isClubMember: _.cloneDeep(this.quoteEnquiry.isClubMember),
    };

    //METHOD: SUBSCRIBE TO THE this.updateQuoteForm TO DETECT CHANGES
    this.updateQuoteForm.valueChanges.subscribe((formValues) => {
      this.onFormChanges(formValues);
    });

    this.toggleCalenderBasedOnTripSelection(this.selectedTripLabel);
  }

  // Method to be triggered on form changes if values are different from the initial values
  onFormChanges(formValues: any): void {
    if (!formValues.rangeDates || formValues.rangeDates < 1) return;
    let newDepartDate =
      this.quoteEnquiry.tripType === 'Single'
        ? moment(formValues.rangeDates[0]).format('DD/MM/YYYY')
        : moment(formValues.rangeDates).format('DD/MM/YYYY');

    let newReturnDate =
      this.quoteEnquiry.tripType === 'Single'
        ? moment(formValues.rangeDates[1]).format('DD/MM/YYYY')
        : moment(this.returnDateAMT).format('DD/MM/YYYY');

    if (
      this.initialQuoteFormValues.tripType != this.selectedTripLabel ||
      this.initialQuoteFormValues.age1 != formValues.age1 ||
      this.initialQuoteFormValues.age2 != formValues.age2 ||
      this.initialQuoteFormValues.departDate != newDepartDate ||
      this.initialQuoteFormValues.returnDate != newReturnDate ||
      this.initialQuoteFormValues.dependentsCount !=
        formValues.dependentsCount ||
      this.checkForChanges(formValues)
    ) {
      this.hasQuoteFormValueChange = true;
    } else {
      this.hasQuoteFormValueChange = false;
    }
  }

  checkForChanges(formValues: any): boolean {
    const initialCountryCodes = this.initialQuoteFormValues.destinations.map(
      (dest: any) => dest.countryCode,
    );
    const currentCountryCodes = formValues.travelDestination.map(
      (dest: any) => dest.countryCode,
    );

    const arraysAreEqual = (arr1: string[], arr2: string[]): boolean => {
      if (arr1.length !== arr2.length) return false;
      arr1.sort();
      arr2.sort();
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    };

    if (!arraysAreEqual(initialCountryCodes, currentCountryCodes)) {
      return true;
    } else {
      return false;
    }
  }

  loadValidations() {
    const validationServiceSub = this.validationService
      //TO DO: CHANGE TO DYNAMIC HANDLING
      .clubValidation()
      .subscribe((response) => {
        this.validationsList = response;
        this.prepareErrorMessages();
        this.setDefaultValuesForValidationRules(response);
      });

    this.subs.push(validationServiceSub);
  }

  prepareErrorMessages() {
    this.validationsList.forEach((validation) => {
      if (!this.errorMessages[validation.field]) {
        this.errorMessages[validation.field] = {};
      }

      validation.rules.forEach((rule: Rule) => {
        this.errorMessages[validation.field][rule.type] = rule.message;
      });
    });
  }

  cannotTravelValidator() {
    return (group: FormGroup): ValidationErrors | null => {
      if (this.selectedCountries && this.selectedCountries.length > 0) {
        let adviceMessage = {} as any;
        const cautionCountries = this.selectedCountries
          .filter(
            (country: any) =>
              country.allowTravel === false &&
              country.isCautionCountry === true,
          )
          .map((country: any) => country.countryName)
          .join(',');
        if (cautionCountries.length > 0) {
          adviceMessage.cautionCountry = `You are unable to complete your quote for the following destination(s): ${cautionCountries}`;
        }

        // Check if there are countries that is considered as do not travel
        this.countriesNotAllowedToTravel = this.selectedCountries
          .filter(
            (country: any) =>
              country.allowTravel === false && country.isDNTCountry === true,
          )
          .map((country: any) => country.countryName);

        if (this.countriesNotAllowedToTravel.length > 0) {
          adviceMessage.cannotTravelCountry = `Travel advice to ${this.countriesNotAllowedToTravel} has been updated to DO NOT TRAVEL by The Australian Department of Foreign Affairs and Trade. Our Policy excludes cover to any country with the advice of Do not travel and cannot offer you a policy to this destination. The Australian Department of Foreign Affairs and Trade.`;
        }
        return adviceMessage;
      }
      return null; // no errors
    };
  }

  cannotTravelDomesticInternational() {
    return (group: FormGroup): ValidationErrors | null => {
      if (this.selectedTripLabel === 'Single') {
        if (this.selectedCountries && this.selectedCountries.length > 0) {
          let isDomestic = this.selectedCountries[0].isDomestic;
          // Check if there are countries that is considered as do not travel
          let validToTravel = this.selectedCountries.every(
            (country: any) => country.isDomestic === isDomestic,
          );
          if (!validToTravel)
            return {
              cannotTravelDomesticInternational:
                'We can not complete your request. You cannot mix Domestic and International travel on a single policy.',
            };
        }
      }
      return null; // no errors
    };
  }

  // This rule only applies to W2C AMT where you cannot select Domestic in AMT. Besides from W2C, clubs allow Domestic AMT
  cannotSelectDomesticAMTW2C() {
    return (group: FormGroup): ValidationErrors | null => {
      if (
        this.activatedClub === 'W2C' &&
        this.selectedTripLabel === 'Multiple'
      ) {
        if (this.selectedCountries && this.selectedCountries.length > 0) {
          let validToTravel = this.selectedCountries.every(
            (country: any) => country.isDomestic === false,
          );
          if (!validToTravel)
            return {
              cannotSelectDomesticAMTW2C:
                'Our Annual Multi-trip plan is an international cover that also includes trips within Australia. When traveling in Australia you are not covered for medical, hospital or dental expenses.',
            };
        }
      }
      return null; // no errors
    };
  }

  datesNotEqualValidator(
    toKey: string,
  ): (group: FormGroup) => ValidationErrors | null {
    return (group: FormGroup): ValidationErrors | null => {
      const toControl = group.controls[toKey];

      if (!toControl.value) {
        // if either control does not have a value yet, return no error
        return null;
      }

      const fromDate = new Date(toControl.value[0]);
      const toDate = new Date(toControl.value[1]);

      if (
        fromDate.getDate() === toDate.getDate() &&
        fromDate.getMonth() === toDate.getMonth() &&
        fromDate.getFullYear() === toDate.getFullYear()
      ) {
        return { datesEqual: "Return Date can't be same as Departure Date." };
      }
      return null; // no errors
    };
  }

  returnDateRequired(
    dateRangeKey: string,
  ): (group: FormGroup) => ValidationErrors | null {
    return (group: FormGroup): ValidationErrors | null => {
      const dateRange = group.controls[dateRangeKey];

      if (!dateRange.value) {
        // if either control does not have a value yet, return no error
        return null;
      }

      const sydneyCurrentDate = new Date(
        new Intl.DateTimeFormat('en-US', {
          timeZone: 'Australia/Sydney',
        }).format(new Date()),
      );

      if (
        this.selectedTripLabel === 'Single' &&
        dateRange.value &&
        dateRange.value.length > 0
      ) {
        let departDate = dateRange.value[0];
        let returnDate = dateRange.value[1];

        sydneyCurrentDate.setDate(sydneyCurrentDate.getDate() + 548);
        if (departDate > sydneyCurrentDate) {
          return {
            returnDateRequired:
              'Departure Date can not be greater than 548 days from today.',
          };
        }

        if (departDate && returnDate == null) {
          return {
            returnDateRequired: 'Please select the Return Date.',
          };
        }
      } else if (this.selectedTripLabel === 'Multiple' && dateRange.value) {
        let departDate = dateRange.value;
        sydneyCurrentDate.setDate(sydneyCurrentDate.getDate() + 548);
        if (departDate > sydneyCurrentDate) {
          return {
            returnDateRequired:
              'Departure Date can not be greater than 548 days from today.',
          };
        }
      }
      return null;
    };
  }

  // Single trip return date must be one year from Departure date.
  SingleTripReturnDateInvalid(
    dateRangeKey: string,
  ): (group: FormGroup) => ValidationErrors | null {
    return (group: FormGroup): ValidationErrors | null => {
      const dateRange = group.controls[dateRangeKey];

      if (!dateRange.value) {
        // if either control does not have a value yet, return no error
        return null;
      }

      if (
        this.selectedTripLabel === 'Single' &&
        dateRange.value &&
        dateRange.value.length > 0
      ) {
        let departDate = dateRange.value[0];
        let returnDate = dateRange.value[1];
        let maxReturnDate = new Date(departDate);
        maxReturnDate = new Date(
          maxReturnDate.setFullYear(departDate.getFullYear() + 1),
        );

        if (returnDate > maxReturnDate) {
          return {
            returnGreaterThanOneYear:
              'Return Date cannot be more than 365 days from Departure date.',
          };
        }
      }
      return null;
    };
  }

  validateAge(traveller: any, event: any) {
    this.validationService.clubValidation().subscribe((data) => {
      for (const validation of data) {
        for (const rule of validation.rules) {
          if (
            rule.type === 'min' &&
            event.target.value != '' &&
            event.target.value < rule.value &&
            validation.field == traveller
          ) {
            this.getAgeMessage(traveller, validation.field, rule.message, true);
            break;
          } else if (
            rule.type === 'max' &&
            event.target.value > rule.value &&
            validation.field == traveller
          ) {
            this.getAgeMessage(traveller, validation.field, rule.message, true);
            break;
          } else if (
            rule.type === 'required' &&
            event.target.value.length < 1 &&
            validation.field == 'first_age'
          ) {
            this.getAgeMessage(traveller, validation.field, rule.message, true);
            break;
          } else {
            this.getAgeMessage(
              traveller,
              validation.field,
              rule.message,
              false,
            );
          }
        }
      }
    });
  }

  getAgeMessage(traveller: any, field: any, message: any, displayMessage: any) {
    if (traveller == 'first_age' && field == 'first_age') {
      this.primaryAgeMessage = displayMessage == true ? message : '';
    } else if (traveller == 'second_age' && field == 'second_age') {
      this.secondaryAgeMessage = displayMessage == true ? message : '';
    }
  }

  increment() {
    this.updateQuoteForm.patchValue({
      dependentsCount: Number(this.updateQuoteForm.value.dependentsCount) + 1,
    });
  }

  decrement() {
    if (this.updateQuoteForm.value.dependentsCount > 0) {
      this.updateQuoteForm.patchValue({
        dependentsCount: Number(this.updateQuoteForm.value.dependentsCount) - 1,
      });
    }
  }

  getPricing(quoteValue: any) {
    this.selectedTabIndex =
      this.localCacheService.getSessionStorage('selectedTabIndex') || 0;
    this.selectedCover =
      this.localCacheService.getSessionStorage('selectedCoverDetail') || {};
    this.selectedTripLenghtLabel =
      this.localCacheService.getSessionStorage('tripLenghtLabel') || '';
    let domesticCountry = quoteValue.travelDestination.every(
      (country: any) => country.isDomestic === true,
    );

    this.localCacheService.saveSessionStorage(
      'domesticCountry',
      domesticCountry,
    );
    const isCruiseCountry = quoteValue.travelDestination.find(
      (item: any) => item.countryCode === 'AUC',
    );
    this.isCruiseCountry = isCruiseCountry ? true : false;
    this.localCacheService.saveSessionStorage(
      'isCruiseCountry',
      this.isCruiseCountry,
    );
    this.isDomestic = domesticCountry;
    this.isTier1 = false;
    this.isTier2 = false;
    this.isTier3 = false;
    this.isMostPopular = false;
    this.currentTier = '';
    this.tabs = [];
    if (this.selectedTabIndex === 0) {
      if (quoteValue.isSingleTrip && this.isDomestic) {
        this.currentTier = 'Domestic';
      } else if (quoteValue.isSingleTrip && !this.isDomestic) {
        this.currentTier = 'Tier3';
      } else if (!this.quoteEnquiry.isSingleTrip && this.isDomestic) {
        this.currentTier = 'Annual Multi Trip';
      } else {
        this.currentTier = 'Annual Multi Trip';
      }
    } else if (this.selectedTabIndex === 1) {
      if (quoteValue.isSingleTrip && this.isDomestic) {
        this.currentTier = 'Cancellation';
      } else {
        this.currentTier = 'Tier2';
      }
    } else if (this.selectedTabIndex === 2) {
      if (quoteValue.isSingleTrip && this.isDomestic) {
        this.currentTier = 'Car';
      } else {
        this.currentTier = 'Tier1';
      }
    }
    this.localCacheService.removeSessionStorage('pricingDetail');
    this.localCacheService.removeSessionStorage('domesticPrice');
    this.localCacheService.removeSessionStorage('basicPrice');
    this.localCacheService.removeSessionStorage('essentialPrice');
    this.localCacheService.removeSessionStorage('topPrice');
    this.localCacheService.removeSessionStorage('annualPrice');
    this.pricingService.getInitialPricing(quoteValue).subscribe((response) => {
      this.localCacheService.saveSessionStorage('pricingDetail', response);
      if (this.activatedClub === 'W2C') {
        this.initializeProductCards(response);
      } else {
        this.initializeOtherClubProductCards(response);
      }
    });
  }

  initializeProductCards(response: any) {
    if (response) {
      for (let i = 0; i < response.length; i++) {
        if (response[i].type === 'Tier3' && this.isDomestic) {
          const excessValues = response[i].products.map(
            (item: any) => item.excess,
          );
          this.domesticPrice.excessValues = Array.from(
            new Set(excessValues),
          ).sort((a: any, b: any) => {
            const valueA = parseInt(a.substring(1));
            const valueB = parseInt(b.substring(1));
            return valueA - valueB;
          });
          const productAlias = response[i].products.map(
            (item: any) => item.productAlias,
          );
          if (
            !this.isEmpty(this.selectedCover) &&
            this.selectedCover.selectedCoverTitle === productAlias[0]
          ) {
            for (let j = 0; j < response[i].products.length; j++) {
              if (
                response[i].products[j].excess ===
                  this.selectedCover.selectedPrice.excess &&
                response[i].products[j].isSki ===
                  this.selectedCover.selectedPrice.isSki
              ) {
                this.domesticPrice.selectedCoverTitle = productAlias[0];
                this.domesticPrice.selectedPrice = response[i].products[j];
              }
            }
          } else {
            for (let j = 0; j < response[i].products.length; j++) {
              if (response[i].defaultExcess) {
                if (
                  response[i].products[j].excess ===
                    response[i].defaultExcess &&
                  response[i].products[j].isSki === false
                ) {
                  this.domesticPrice.selectedCoverTitle = productAlias[0];
                  this.domesticPrice.selectedPrice = response[i].products[j];
                }
              } else {
                if (
                  response[i].products[j].excess ===
                    this.domesticPrice.excessValues[0] &&
                  response[i].products[j].isSki === false
                ) {
                  this.domesticPrice.selectedCoverTitle = productAlias[0];
                  this.domesticPrice.selectedPrice = response[i].products[j];
                }
              }
            }
          }
          this.tabs.push({
            type: response[i].type,
            mostPopular: response[i].mostPopular,
            price: this.domesticPrice,
          });
          this.localCacheService.saveSessionStorage(
            'domesticPrice',
            this.domesticPrice,
          );
        } else {
          if (response[i].type === 'Tier1') {
            if (response[i].mostPopular) {
              this.isMostPopular = true;
              this.alignment = 'right';
            }
            this.isTier1 = true;
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.basicPrice.excessValues = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  response[i].products[j].isCruise === this.isCruiseCountry
                    ? true
                    : this.selectedCover.selectedPrice.isCruise
                ) {
                  this.basicPrice.selectedCoverTitle = productAlias[0];
                  this.basicPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].isCruise === this.isCruiseCountry
                  ) {
                    this.basicPrice.selectedCoverTitle = productAlias[0];
                    this.basicPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.basicPrice.excessValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry
                  ) {
                    this.basicPrice.selectedCoverTitle = productAlias[0];
                    this.basicPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.basicPrice,
            });
            this.localCacheService.saveSessionStorage(
              'basicPrice',
              this.basicPrice,
            );
          } else if (response[i].type === 'Tier2') {
            if (response[i].mostPopular) {
              this.isMostPopular = true;
              this.alignment = 'center';
            }
            this.isTier2 = true;
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.essentialPrice.excessValues = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  response[i].products[j].isCruise === this.isCruiseCountry
                    ? true
                    : this.selectedCover.selectedPrice.isCruise &&
                      response[i].products[j].isSki ===
                        this.selectedCover.selectedPrice.isSki
                ) {
                  this.essentialPrice.selectedCoverTitle = productAlias[0];
                  this.essentialPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.essentialPrice.selectedCoverTitle = productAlias[0];
                    this.essentialPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.essentialPrice.excessValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.essentialPrice.selectedCoverTitle = productAlias[0];
                    this.essentialPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.essentialPrice,
            });
            this.localCacheService.saveSessionStorage(
              'essentialPrice',
              this.essentialPrice,
            );
          } else if (response[i].type === 'Tier3') {
            if (response[i].mostPopular) {
              this.isMostPopular = true;
              this.alignment = 'left';
            }
            this.isTier3 = true;
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.topPrice.excessValues = Array.from(new Set(excessValues)).sort(
              (a: any, b: any) => {
                const valueA = parseInt(a.substring(1));
                const valueB = parseInt(b.substring(1));
                return valueA - valueB;
              },
            );
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  response[i].products[j].isCruise === this.isCruiseCountry
                    ? true
                    : this.selectedCover.selectedPrice.isCruise &&
                      response[i].products[j].isSki ===
                        this.selectedCover.selectedPrice.isSki
                ) {
                  this.topPrice.selectedCoverTitle = productAlias[0];
                  this.topPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.topPrice.selectedCoverTitle = productAlias[0];
                    this.topPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.topPrice.excessValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.topPrice.selectedCoverTitle = productAlias[0];
                    this.topPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.topPrice,
            });
            this.localCacheService.saveSessionStorage(
              'topPrice',
              this.topPrice,
            );
          } else if (response[i].type === 'Annual Multi Trip') {
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.annualPrice.excessValues = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });
            const multiTripLengthValues = response[i].products.map(
              (item: any) => item.multiTripLength,
            );
            this.annualPrice.multiTripLengthValues = Array.from(
              new Set(multiTripLengthValues),
            ).sort();
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  response[i].products[j].multiTripLength ===
                    this.selectedCover.selectedPrice.multiTripLength &&
                  response[i].products[j].isCruise === this.isCruiseCountry
                    ? true
                    : this.selectedCover.selectedPrice.isCruise &&
                      response[i].products[j].isSki ===
                        this.selectedCover.selectedPrice.isSki
                ) {
                  this.annualPrice.selectedCoverTitle = productAlias[0];
                  this.annualPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].multiTripLength ===
                      this.annualPrice.multiTripLengthValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.selectedTripLenghtLabel =
                      this.annualPrice.multiTripLengthValues[0];
                    this.annualPrice.selectedCoverTitle = productAlias[0];
                    this.annualPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.annualPrice.excessValues[0] &&
                    response[i].products[j].multiTripLength ===
                      this.annualPrice.multiTripLengthValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.selectedTripLenghtLabel =
                      this.annualPrice.multiTripLengthValues[0];
                    this.annualPrice.selectedCoverTitle = productAlias[0];
                    this.annualPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.annualPrice,
            });
            this.localCacheService.saveSessionStorage(
              'tripLenghtLabel',
              this.selectedTripLenghtLabel,
            );
            this.localCacheService.saveSessionStorage(
              'annualPrice',
              this.annualPrice,
            );
          }
        }
      }
      if (this.tabs.length > 0) {
        this.tabs.sort((a, b) => {
          if (a.type > b.type) {
            return -1;
          } else if (a.type < b.type) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      if (this.tabs.length > 0) {
        if (!this.isEmpty(this.selectedCover)) {
          const result = this.tabs.filter(
            (tab) =>
              tab.price.selectedCoverTitle ===
              this.selectedCover.selectedCoverTitle,
          );
          if (result.length > 0) {
            this.selectedCover = result[0].price;
            this.localCacheService.saveSessionStorage(
              'selectedCoverDetail',
              this.selectedCover,
            );
          } else {
            this.selectedCover = this.tabs[0].price;
            this.localCacheService.saveSessionStorage(
              'selectedCoverDetail',
              this.selectedCover,
            );
            this.localCacheService.saveSessionStorage('selectedTabIndex', 0);
          }
        } else {
          this.selectedCover = this.tabs[0].price;
          this.localCacheService.saveSessionStorage(
            'selectedCoverDetail',
            this.selectedCover,
          );
          this.localCacheService.saveSessionStorage('selectedTabIndex', 0);
        }
      }
    }
    this.selectedTabIndex = this.localCacheService.getSessionStorage(
      'selectedTabIndex',
    ) as number;
    this.onTabChange({ index: this.selectedTabIndex });
  }

  initializeOtherClubProductCards(response: any) {
    if (response) {
      for (let i = 0; i < response.length; i++) {
        if (this.isDomestic) {
          if (response[i].type === 'Tier1') {
            if (response[i].mostPopular) {
              this.isMostPopular = true;
              this.alignment = 'right';
            }
            this.isTier1 = true;
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.basicPrice.excessValues = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });
            // const defaultExcess = response[i].products.map(
            //   (item: any) => item.defaultExcess,
            // );
            // const defaultExcessCondition = defaultExcess.find(
            //   (de: any) => de === true,
            // );
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                  this.selectedCover.selectedPrice.excess
                ) {
                  this.basicPrice.selectedCoverTitle = productAlias[0];
                  this.basicPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess === response[i].defaultExcess
                  ) {
                    this.basicPrice.selectedCoverTitle = productAlias[0];
                    this.basicPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                    this.basicPrice.excessValues[0]
                  ) {
                    this.basicPrice.selectedCoverTitle = productAlias[0];
                    this.basicPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.basicPrice,
            });
            this.localCacheService.saveSessionStorage(
              'basicPrice',
              this.basicPrice,
            );
          } else if (response[i].type === 'Tier2') {
            if (response[i].mostPopular) {
              this.isMostPopular = true;
              this.alignment = 'center';
            }
            this.isTier2 = true;
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.essentialPrice.excessValues = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                this.essentialPrice.selectedCoverTitle = productAlias[0];
                this.essentialPrice.selectedPrice = response[i].products[j];
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                this.essentialPrice.selectedCoverTitle = productAlias[0];
                this.essentialPrice.selectedPrice = response[i].products[j];
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.essentialPrice,
            });
            this.localCacheService.saveSessionStorage(
              'essentialPrice',
              this.essentialPrice,
            );
          } else if (response[i].type === 'Tier3') {
            if (response[i].mostPopular) {
              this.isMostPopular = true;
              this.alignment = 'left';
            }
            this.isTier3 = true;
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.topPrice.excessValues = Array.from(new Set(excessValues)).sort(
              (a: any, b: any) => {
                const valueA = parseInt(a.substring(1));
                const valueB = parseInt(b.substring(1));
                return valueA - valueB;
              },
            );
            // const defaultExcess = response[i].products.map(
            //   (item: any) => item.defaultExcess,
            // );
            // const defaultExcessCondition = defaultExcess.find(
            //   (de: any) => de === true,
            // );
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  response[i].products[j].isSki ===
                    this.selectedCover.selectedPrice.isSki
                ) {
                  this.topPrice.selectedCoverTitle = productAlias[0];
                  this.topPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].isSki === false
                  ) {
                    this.topPrice.selectedCoverTitle = productAlias[0];
                    this.topPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.topPrice.excessValues[0] &&
                    response[i].products[j].isSki === false
                  ) {
                    this.topPrice.selectedCoverTitle = productAlias[0];
                    this.topPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.topPrice,
            });
            this.localCacheService.saveSessionStorage(
              'topPrice',
              this.topPrice,
            );
          } else if (response[i].type === 'Annual Multi Trip') {
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.annualPrice.excessValues = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });
            // const defaultExcess = response[i].products.map(
            //   (item: any) => item.defaultExcess,
            // );
            // const defaultExcessCondition = defaultExcess.find(
            //   (de: any) => de === true,
            // );
            const multiTripLengthValues = response[i].products.map(
              (item: any) => item.multiTripLength,
            );
            this.annualPrice.multiTripLengthValues = Array.from(
              new Set(multiTripLengthValues),
            ).sort();
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  response[i].products[j].multiTripLength ===
                    this.selectedCover.selectedPrice.multiTripLength &&
                  (response[i].products[j].isCruise === this.isCruiseCountry
                    ? true
                    : this.selectedCover.selectedPrice.isCruise) &&
                  response[i].products[j].isSki ===
                    this.selectedCover.selectedPrice.isSki
                ) {
                  this.annualPrice.selectedCoverTitle = productAlias[0];
                  this.annualPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].multiTripLength ===
                      this.annualPrice.multiTripLengthValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.selectedTripLenghtLabel =
                      this.annualPrice.multiTripLengthValues[0];
                    this.annualPrice.selectedCoverTitle = productAlias[0];
                    this.annualPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.annualPrice.excessValues[0] &&
                    response[i].products[j].multiTripLength ===
                      this.annualPrice.multiTripLengthValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.selectedTripLenghtLabel =
                      this.annualPrice.multiTripLengthValues[0];
                    this.annualPrice.selectedCoverTitle = productAlias[0];
                    this.annualPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.annualPrice,
            });
            this.localCacheService.saveSessionStorage(
              'tripLenghtLabel',
              this.selectedTripLenghtLabel,
            );
            this.localCacheService.saveSessionStorage(
              'annualPrice',
              this.annualPrice,
            );
          }
        } else {
          if (response[i].type === 'Tier1') {
            if (response[i].mostPopular) {
              this.isMostPopular = true;
              this.alignment = 'right';
            }
            this.isTier1 = true;
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            // const defaultExcess = response[i].products.map(
            //   (item: any) => item.defaultExcess,
            // );
            // const defaultExcessCondition = defaultExcess.find(
            //   (de: any) => de === true,
            // );
            this.basicPrice.excessValues = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  (response[i].products[j].isCruise === this.isCruiseCountry
                    ? true
                    : this.selectedCover.selectedPrice.isCruise)
                ) {
                  this.basicPrice.selectedCoverTitle = productAlias[0];
                  this.basicPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].isCruise === this.isCruiseCountry
                  ) {
                    this.basicPrice.selectedCoverTitle = productAlias[0];
                    this.basicPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.basicPrice.excessValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry
                  ) {
                    this.basicPrice.selectedCoverTitle = productAlias[0];
                    this.basicPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.basicPrice,
            });
            this.localCacheService.saveSessionStorage(
              'basicPrice',
              this.basicPrice,
            );
          } else if (response[i].type === 'Tier2') {
            if (response[i].mostPopular) {
              this.isMostPopular = true;
              this.alignment = 'center';
            }
            this.isTier2 = true;
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.essentialPrice.excessValues = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });
            // const defaultExcess = response[i].products.map(
            //   (item: any) => item.defaultExcess,
            // );
            // const defaultExcessCondition = defaultExcess.find(
            //   (de: any) => de === true,
            // );
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  (response[i].products[j].isCruise === this.isCruiseCountry
                    ? true
                    : this.selectedCover.selectedPrice.isCruise) &&
                  response[i].products[j].isSki ===
                    this.selectedCover.selectedPrice.isSki
                ) {
                  this.essentialPrice.selectedCoverTitle = productAlias[0];
                  this.essentialPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.essentialPrice.selectedCoverTitle = productAlias[0];
                    this.essentialPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.essentialPrice.excessValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.essentialPrice.selectedCoverTitle = productAlias[0];
                    this.essentialPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.essentialPrice,
            });
            this.localCacheService.saveSessionStorage(
              'essentialPrice',
              this.essentialPrice,
            );
          } else if (response[i].type === 'Tier3') {
            if (response[i].mostPopular) {
              this.isMostPopular = true;
              this.alignment = 'left';
            }
            this.isTier3 = true;
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.topPrice.excessValues = Array.from(new Set(excessValues)).sort(
              (a: any, b: any) => {
                const valueA = parseInt(a.substring(1));
                const valueB = parseInt(b.substring(1));
                return valueA - valueB;
              },
            );
            // const defaultExcess = response[i].products.map(
            //   (item: any) => item.defaultExcess,
            // );
            // const defaultExcessCondition = defaultExcess.find(
            //   (de: any) => de === true,
            // );
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  (response[i].products[j].isCruise === this.isCruiseCountry
                    ? true
                    : this.selectedCover.selectedPrice.isCruise) &&
                  response[i].products[j].isSki ===
                    this.selectedCover.selectedPrice.isSki
                ) {
                  this.topPrice.selectedCoverTitle = productAlias[0];
                  this.topPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.topPrice.selectedCoverTitle = productAlias[0];
                    this.topPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.topPrice.excessValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.topPrice.selectedCoverTitle = productAlias[0];
                    this.topPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.topPrice,
            });
            this.localCacheService.saveSessionStorage(
              'topPrice',
              this.topPrice,
            );
          } else if (response[i].type === 'Annual Multi Trip') {
            const excessValues = response[i].products.map(
              (item: any) => item.excess,
            );
            this.annualPrice.excessValues = Array.from(
              new Set(excessValues),
            ).sort((a: any, b: any) => {
              const valueA = parseInt(a.substring(1));
              const valueB = parseInt(b.substring(1));
              return valueA - valueB;
            });
            // const defaultExcess = response[i].products.map(
            //   (item: any) => item.defaultExcess,
            // );
            // const defaultExcessCondition = defaultExcess.find(
            //   (de: any) => de === true,
            // );
            const multiTripLengthValues = response[i].products.map(
              (item: any) => item.multiTripLength,
            );
            this.annualPrice.multiTripLengthValues = Array.from(
              new Set(multiTripLengthValues),
            ).sort();
            const productAlias = response[i].products.map(
              (item: any) => item.productAlias,
            );
            if (
              !this.isEmpty(this.selectedCover) &&
              this.selectedCover.selectedCoverTitle === productAlias[0]
            ) {
              for (let j = 0; j < response[i].products.length; j++) {
                if (
                  response[i].products[j].excess ===
                    this.selectedCover.selectedPrice.excess &&
                  response[i].products[j].multiTripLength ===
                    this.selectedCover.selectedPrice.multiTripLength &&
                  (response[i].products[j].isCruise === this.isCruiseCountry
                    ? true
                    : this.selectedCover.selectedPrice.isCruise) &&
                  response[i].products[j].isSki ===
                    this.selectedCover.selectedPrice.isSki
                ) {
                  this.annualPrice.selectedCoverTitle = productAlias[0];
                  this.annualPrice.selectedPrice = response[i].products[j];
                }
              }
            } else {
              for (let j = 0; j < response[i].products.length; j++) {
                if (response[i].defaultExcess) {
                  if (
                    response[i].products[j].excess ===
                      response[i].defaultExcess &&
                    response[i].products[j].multiTripLength ===
                      this.annualPrice.multiTripLengthValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.selectedTripLenghtLabel =
                      this.annualPrice.multiTripLengthValues[0];
                    this.annualPrice.selectedCoverTitle = productAlias[0];
                    this.annualPrice.selectedPrice = response[i].products[j];
                  }
                } else {
                  if (
                    response[i].products[j].excess ===
                      this.annualPrice.excessValues[0] &&
                    response[i].products[j].multiTripLength ===
                      this.annualPrice.multiTripLengthValues[0] &&
                    response[i].products[j].isCruise === this.isCruiseCountry &&
                    response[i].products[j].isSki === false
                  ) {
                    this.selectedTripLenghtLabel =
                      this.annualPrice.multiTripLengthValues[0];
                    this.annualPrice.selectedCoverTitle = productAlias[0];
                    this.annualPrice.selectedPrice = response[i].products[j];
                  }
                }
              }
            }
            this.tabs.push({
              type: response[i].type,
              mostPopular: response[i].mostPopular,
              price: this.annualPrice,
            });
            this.localCacheService.saveSessionStorage(
              'tripLenghtLabel',
              this.selectedTripLenghtLabel,
            );
            this.localCacheService.saveSessionStorage(
              'annualPrice',
              this.annualPrice,
            );
          }
        }
      }
      if (this.tabs.length > 0) {
        this.tabs.sort((a, b) => {
          if (a.type > b.type) {
            return -1;
          } else if (a.type < b.type) {
            return 1;
          } else {
            return 0;
          }
        });
      }
      if (this.tabs.length > 0) {
        if (!this.isEmpty(this.selectedCover)) {
          const result = this.tabs.filter(
            (tab) =>
              tab.price.selectedCoverTitle ===
              this.selectedCover.selectedCoverTitle,
          );
          if (result.length > 0) {
            this.selectedCover = result[0].price;
            this.localCacheService.saveSessionStorage(
              'selectedCoverDetail',
              this.selectedCover,
            );
          } else {
            this.selectedCover = this.tabs[0].price;
            this.localCacheService.saveSessionStorage(
              'selectedCoverDetail',
              this.selectedCover,
            );
            this.localCacheService.saveSessionStorage('selectedTabIndex', 0);
          }
        } else {
          this.selectedCover = this.tabs[0].price;
          this.localCacheService.saveSessionStorage(
            'selectedCoverDetail',
            this.selectedCover,
          );
          this.localCacheService.saveSessionStorage('selectedTabIndex', 0);
        }
      }
    }
    this.selectedTabIndex = this.localCacheService.getSessionStorage(
      'selectedTabIndex',
    ) as number;

    this.onTabChange({ index: this.selectedTabIndex });
  }

  isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  onTabChange(event: any) {
    this.selectedTabIndex = event.index;
    if (this.selectedTabIndex === 0) {
      if (this.quoteEnquiry.isSingleTrip && this.isDomestic) {
        this.currentTier = 'Domestic';
      } else if (this.quoteEnquiry.isSingleTrip && !this.isDomestic) {
        this.currentTier = 'Tier3';
      } else if (!this.quoteEnquiry.isSingleTrip && this.isDomestic) {
        this.currentTier = 'Annual Multi Trip';
      } else {
        this.currentTier = 'Annual Multi Trip';
      }
    } else if (this.selectedTabIndex === 1) {
      if (this.quoteEnquiry.isSingleTrip && this.isDomestic) {
        this.currentTier = 'Cancellation';
      } else {
        this.currentTier = 'Tier2';
      }
    } else if (this.selectedTabIndex === 2) {
      if (this.quoteEnquiry.isSingleTrip && this.isDomestic) {
        this.currentTier = 'Car';
      } else {
        this.currentTier = 'Tier1';
      }
    }
    this.pricingDetail =
      this.localCacheService.getSessionStorage('pricingDetail') || {};
    if (this.pricingDetail.length === 3) {
      if (this.selectedTabIndex === 0) {
        this.selectedCover =
          this.localCacheService.getSessionStorage('topPrice') || {};
        this.tabs[0].price = this.selectedCover;
      } else if (this.selectedTabIndex === 1) {
        this.selectedCover =
          this.localCacheService.getSessionStorage('essentialPrice') || {};
        this.tabs[1].price = this.selectedCover;
      } else if (this.selectedTabIndex === 2) {
        this.selectedCover =
          this.localCacheService.getSessionStorage('basicPrice') || {};
        this.tabs[2].price = this.selectedCover;
      }
    } else if (this.pricingDetail.length === 2) {
      if (this.isTier3 && this.isTier2) {
        if (this.selectedTabIndex === 0) {
          this.selectedCover =
            this.localCacheService.getSessionStorage('topPrice') || {};
        } else if (this.selectedTabIndex === 1) {
          this.selectedCover =
            this.localCacheService.getSessionStorage('essentialPrice') || {};
        }
      } else if (this.isTier2 && this.isTier1) {
        if (this.selectedTabIndex === 0) {
          this.selectedCover =
            this.localCacheService.getSessionStorage('essentialPrice') || {};
        } else if (this.selectedTabIndex === 1) {
          this.selectedCover =
            this.localCacheService.getSessionStorage('basicPrice') || {};
        }
      } else if (this.isTier3 && this.isTier1) {
        if (this.selectedTabIndex === 0) {
          this.selectedCover =
            this.localCacheService.getSessionStorage('topPrice') || {};
        } else if (this.selectedTabIndex === 1) {
          this.selectedCover =
            this.localCacheService.getSessionStorage('basicPrice') || {};
        }
      }
    }
    this.localCacheService.saveSessionStorage(
      'selectedTabIndex',
      this.selectedTabIndex,
    );
    this.localCacheService.saveSessionStorage(
      'selectedCoverDetail',
      this.selectedCover,
    );
    //Segment
    const segmentCoverPrice = this.selectedCover.selectedPrice;
    const productPlans = {
      eventName: 'step_one_change_cover_plan',
      planType: segmentCoverPrice.productType,
      excessAmount: segmentCoverPrice.excess,
      cruise: segmentCoverPrice.isCruise,
      ski: segmentCoverPrice.isSki,
      retailPrice: segmentCoverPrice.basePremium,
      selectedPriceDetails: segmentCoverPrice,
    };

    this.segmentService.segmentSingleTripOnChangePlanType(productPlans);
  }

  onOptionChange(event: any) {
    this.pricingDetail =
      this.localCacheService.getSessionStorage('pricingDetail') || {};
    this.selectedCover =
      this.localCacheService.getSessionStorage('selectedCoverDetail') || {};
    if (this.pricingDetail) {
      for (let i = 0; i < this.pricingDetail.length; i++) {
        if (
          this.isDomestic &&
          this.pricingDetail[i].type != 'Annual Multi Trip'
        ) {
          if (this.activatedClub === 'RACT') {
            if (
              this.pricingDetail[i].type === 'Tier1' &&
              this.selectedTabIndex === 2
            ) {
              for (let j = 0; j < this.pricingDetail[i].products.length; j++) {
                if (this.pricingDetail[i].products[j].excess === event.excess) {
                  this.basicPrice.selectedPrice =
                    this.pricingDetail[i].products[j];
                  this.selectedCover.selectedPrice =
                    this.pricingDetail[i].products[j];
                }
              }
              this.tabs.forEach((obj) => {
                if (obj.type === 'Tier1') {
                  obj.price.selectedPrice = this.basicPrice.selectedPrice;
                }
              });
              this.localCacheService.saveSessionStorage(
                'basicPrice',
                this.basicPrice,
              );
              this.localCacheService.saveSessionStorage(
                'selectedCoverDetail',
                this.selectedCover,
              );
            } else if (
              this.pricingDetail[i].type === 'Tier3' &&
              this.selectedTabIndex === 0
            ) {
              for (let j = 0; j < this.pricingDetail[i].products.length; j++) {
                if (
                  this.pricingDetail[i].products[j].excess === event.excess &&
                  this.pricingDetail[i].products[j].isSki === event.isSki
                ) {
                  this.topPrice.selectedPrice =
                    this.pricingDetail[i].products[j];
                  this.selectedCover.selectedPrice =
                    this.pricingDetail[i].products[j];
                }
              }
              this.tabs.forEach((obj) => {
                if (obj.type === 'Tier3') {
                  obj.price.selectedPrice = this.topPrice.selectedPrice;
                }
              });
              this.localCacheService.saveSessionStorage(
                'topPrice',
                this.topPrice,
              );
              this.localCacheService.saveSessionStorage(
                'selectedCoverDetail',
                this.selectedCover,
              );
            }
          } else {
            for (let j = 0; j < this.pricingDetail[i].products.length; j++) {
              if (
                this.pricingDetail[i].products[j].excess === event.excess &&
                this.pricingDetail[i].products[j].isSki === event.isSki
              ) {
                this.domesticPrice.selectedPrice =
                  this.pricingDetail[i].products[j];
                this.selectedCover.selectedPrice =
                  this.pricingDetail[i].products[j];
              }
            }
            this.localCacheService.saveSessionStorage(
              'domesticPrice',
              this.domesticPrice,
            );
            this.localCacheService.saveSessionStorage(
              'selectedCoverDetail',
              this.selectedCover,
            );
          }
        } else if (this.pricingDetail[i].type === 'Annual Multi Trip') {
          for (let j = 0; j < this.pricingDetail[i].products.length; j++) {
            if (
              this.pricingDetail[i].products[j].excess === event.excess &&
              this.pricingDetail[i].products[j].multiTripLength ===
                this.selectedCover?.selectedPrice?.multiTripLength &&
              this.pricingDetail[i].products[j].isCruise === event.isCruise &&
              this.pricingDetail[i].products[j].isSki === event.isSki
            ) {
              this.annualPrice.selectedPrice =
                this.pricingDetail[i].products[j];
              this.selectedCover.selectedPrice =
                this.pricingDetail[i].products[j];
            }
          }
          this.localCacheService.saveSessionStorage(
            'annualPrice',
            this.annualPrice,
          );
          this.localCacheService.saveSessionStorage(
            'selectedCoverDetail',
            this.selectedCover,
          );
        } else {
          if (this.pricingDetail.length === 3) {
            if (
              this.pricingDetail[i].type === 'Tier1' &&
              this.selectedTabIndex === 2
            ) {
              for (let j = 0; j < this.pricingDetail[i].products.length; j++) {
                if (
                  this.pricingDetail[i].products[j].excess === event.excess &&
                  this.pricingDetail[i].products[j].isCruise === event.isCruise
                ) {
                  this.basicPrice.selectedPrice =
                    this.pricingDetail[i].products[j];
                  this.selectedCover.selectedPrice =
                    this.pricingDetail[i].products[j];
                }
              }
              this.tabs.forEach((obj) => {
                if (obj.type === 'Tier1') {
                  obj.price.selectedPrice = this.basicPrice.selectedPrice;
                }
              });
              this.localCacheService.saveSessionStorage(
                'basicPrice',
                this.basicPrice,
              );
              this.localCacheService.saveSessionStorage(
                'selectedCoverDetail',
                this.selectedCover,
              );
            } else if (
              this.pricingDetail[i].type === 'Tier2' &&
              this.selectedTabIndex === 1
            ) {
              for (let j = 0; j < this.pricingDetail[i].products.length; j++) {
                if (
                  this.pricingDetail[i].products[j].excess === event.excess &&
                  this.pricingDetail[i].products[j].isCruise ===
                    event.isCruise &&
                  this.pricingDetail[i].products[j].isSki === event.isSki
                ) {
                  this.essentialPrice.selectedPrice =
                    this.pricingDetail[i].products[j];
                  this.selectedCover.selectedPrice =
                    this.pricingDetail[i].products[j];
                }
              }
              this.tabs.forEach((obj) => {
                if (obj.type === 'Tier2') {
                  obj.price.selectedPrice = this.essentialPrice.selectedPrice;
                }
              });
              this.localCacheService.saveSessionStorage(
                'essentialPrice',
                this.essentialPrice,
              );
              this.localCacheService.saveSessionStorage(
                'selectedCoverDetail',
                this.selectedCover,
              );
            } else if (
              this.pricingDetail[i].type === 'Tier3' &&
              this.selectedTabIndex === 0
            ) {
              for (let j = 0; j < this.pricingDetail[i].products.length; j++) {
                if (
                  this.pricingDetail[i].products[j].excess === event.excess &&
                  this.pricingDetail[i].products[j].isCruise ===
                    event.isCruise &&
                  this.pricingDetail[i].products[j].isSki === event.isSki
                ) {
                  this.topPrice.selectedPrice =
                    this.pricingDetail[i].products[j];
                  this.selectedCover.selectedPrice =
                    this.pricingDetail[i].products[j];
                }
              }
              this.tabs.forEach((obj) => {
                if (obj.type === 'Tier3') {
                  obj.price.selectedPrice = this.topPrice.selectedPrice;
                }
              });
              this.localCacheService.saveSessionStorage(
                'topPrice',
                this.topPrice,
              );
              this.localCacheService.saveSessionStorage(
                'selectedCoverDetail',
                this.selectedCover,
              );
            }
          } else if (this.pricingDetail.length === 2) {
            if (this.isTier3 && this.isTier2) {
              if (this.isTier3) {
                if (
                  this.pricingDetail[i].type === 'Tier3' &&
                  this.selectedTabIndex === 0
                ) {
                  for (
                    let j = 0;
                    j < this.pricingDetail[i].products.length;
                    j++
                  ) {
                    if (
                      this.pricingDetail[i].products[j].excess ===
                        event.excess &&
                      this.pricingDetail[i].products[j].isCruise ===
                        event.isCruise &&
                      this.pricingDetail[i].products[j].isSki === event.isSki
                    ) {
                      this.topPrice.selectedPrice =
                        this.pricingDetail[i].products[j];
                      this.selectedCover.selectedPrice =
                        this.pricingDetail[i].products[j];
                    }
                  }
                  this.tabs.forEach((obj) => {
                    if (obj.type === 'Tier3') {
                      obj.price.selectedPrice = this.topPrice.selectedPrice;
                    }
                  });
                  this.localCacheService.saveSessionStorage(
                    'topPrice',
                    this.topPrice,
                  );
                  this.localCacheService.saveSessionStorage(
                    'selectedCoverDetail',
                    this.selectedCover,
                  );
                }
              } else {
                if (
                  this.pricingDetail[i].type === 'Tier2' &&
                  this.selectedTabIndex === 1
                ) {
                  for (
                    let j = 0;
                    j < this.pricingDetail[i].products.length;
                    j++
                  ) {
                    if (
                      this.pricingDetail[i].products[j].excess ===
                        event.excess &&
                      this.pricingDetail[i].products[j].isCruise ===
                        event.isCruise &&
                      this.pricingDetail[i].products[j].isSki === event.isSki
                    ) {
                      this.essentialPrice.selectedPrice =
                        this.pricingDetail[i].products[j];
                      this.selectedCover.selectedPrice =
                        this.pricingDetail[i].products[j];
                    }
                  }
                  this.tabs.forEach((obj) => {
                    if (obj.type === 'Tier2') {
                      obj.price.selectedPrice =
                        this.essentialPrice.selectedPrice;
                    }
                  });
                  this.localCacheService.saveSessionStorage(
                    'essentialPrice',
                    this.essentialPrice,
                  );
                  this.localCacheService.saveSessionStorage(
                    'selectedCoverDetail',
                    this.selectedCover,
                  );
                }
              }
            } else if (this.isTier2 && this.isTier1) {
              if (this.isTier2) {
                if (
                  this.pricingDetail[i].type === 'Tier2' &&
                  this.selectedTabIndex === 0
                ) {
                  for (
                    let j = 0;
                    j < this.pricingDetail[i].products.length;
                    j++
                  ) {
                    if (
                      this.pricingDetail[i].products[j].excess ===
                        event.excess &&
                      this.pricingDetail[i].products[j].isCruise ===
                        event.isCruise &&
                      this.pricingDetail[i].products[j].isSki === event.isSki
                    ) {
                      this.essentialPrice.selectedPrice =
                        this.pricingDetail[i].products[j];
                      this.selectedCover.selectedPrice =
                        this.pricingDetail[i].products[j];
                    }
                  }
                  this.tabs.forEach((obj) => {
                    if (obj.type === 'Tier2') {
                      obj.price.selectedPrice =
                        this.essentialPrice.selectedPrice;
                    }
                  });
                  this.localCacheService.saveSessionStorage(
                    'essentialPrice',
                    this.essentialPrice,
                  );
                  this.localCacheService.saveSessionStorage(
                    'selectedCoverDetail',
                    this.selectedCover,
                  );
                }
              } else {
                if (
                  this.pricingDetail[i].type === 'Tier1' &&
                  this.selectedTabIndex === 1
                ) {
                  for (
                    let j = 0;
                    j < this.pricingDetail[i].products.length;
                    j++
                  ) {
                    if (
                      this.pricingDetail[i].products[j].excess ===
                        event.excess &&
                      this.pricingDetail[i].products[j].isCruise ===
                        event.isCruise
                    ) {
                      this.basicPrice.selectedPrice =
                        this.pricingDetail[i].products[j];
                      this.selectedCover.selectedPrice =
                        this.pricingDetail[i].products[j];
                    }
                  }
                  this.tabs.forEach((obj) => {
                    if (obj.type === 'Tier1') {
                      obj.price.selectedPrice = this.basicPrice.selectedPrice;
                    }
                  });
                  this.localCacheService.saveSessionStorage(
                    'basicPrice',
                    this.basicPrice,
                  );
                  this.localCacheService.saveSessionStorage(
                    'selectedCoverDetail',
                    this.selectedCover,
                  );
                }
              }
            } else if (this.isTier3 && this.isTier1) {
              if (this.isTier3) {
                if (
                  this.pricingDetail[i].type === 'Tier3' &&
                  this.selectedTabIndex === 0
                ) {
                  for (
                    let j = 0;
                    j < this.pricingDetail[i].products.length;
                    j++
                  ) {
                    if (
                      this.pricingDetail[i].products[j].excess ===
                        event.excess &&
                      this.pricingDetail[i].products[j].isCruise ===
                        event.isCruise &&
                      this.pricingDetail[i].products[j].isSki === event.isSki
                    ) {
                      this.topPrice.selectedPrice =
                        this.pricingDetail[i].products[j];
                      this.selectedCover.selectedPrice =
                        this.pricingDetail[i].products[j];
                    }
                  }
                  this.tabs.forEach((obj) => {
                    if (obj.type === 'Tier3') {
                      obj.price.selectedPrice = this.topPrice.selectedPrice;
                    }
                  });
                  this.localCacheService.saveSessionStorage(
                    'topPrice',
                    this.topPrice,
                  );
                  this.localCacheService.saveSessionStorage(
                    'selectedCoverDetail',
                    this.selectedCover,
                  );
                }
              } else {
                if (
                  this.pricingDetail[i].type === 'Tier1' &&
                  this.selectedTabIndex === 1
                ) {
                  for (
                    let j = 0;
                    j < this.pricingDetail[i].products.length;
                    j++
                  ) {
                    if (
                      this.pricingDetail[i].products[j].excess ===
                        event.excess &&
                      this.pricingDetail[i].products[j].isCruise ===
                        event.isCruise
                    ) {
                      this.basicPrice.selectedPrice =
                        this.pricingDetail[i].products[j];
                      this.selectedCover.selectedPrice =
                        this.pricingDetail[i].products[j];
                    }
                  }
                  this.tabs.forEach((obj) => {
                    if (obj.type === 'Tier1') {
                      obj.price.selectedPrice = this.basicPrice.selectedPrice;
                    }
                  });
                  this.localCacheService.saveSessionStorage(
                    'basicPrice',
                    this.basicPrice,
                  );
                  this.localCacheService.saveSessionStorage(
                    'selectedCoverDetail',
                    this.selectedCover,
                  );
                }
              }
            }
          } else if (this.pricingDetail.length === 1) {
            if (this.isTier3) {
              if (
                this.pricingDetail[i].type === 'Tier3' &&
                this.selectedTabIndex === 0
              ) {
                for (
                  let j = 0;
                  j < this.pricingDetail[i].products.length;
                  j++
                ) {
                  if (
                    this.pricingDetail[i].products[j].excess === event.excess &&
                    this.pricingDetail[i].products[j].isCruise ===
                      event.isCruise &&
                    this.pricingDetail[i].products[j].isSki === event.isSki
                  ) {
                    this.topPrice.selectedPrice =
                      this.pricingDetail[i].products[j];
                    this.selectedCover.selectedPrice =
                      this.pricingDetail[i].products[j];
                  }
                }
                this.tabs.forEach((obj) => {
                  if (obj.type === 'Tier3') {
                    obj.price.selectedPrice = this.topPrice.selectedPrice;
                  }
                });
                this.localCacheService.saveSessionStorage(
                  'topPrice',
                  this.topPrice,
                );
                this.localCacheService.saveSessionStorage(
                  'selectedCoverDetail',
                  this.selectedCover,
                );
              }
            } else if (this.isTier2) {
              if (
                this.pricingDetail[i].type === 'Tier2' &&
                this.selectedTabIndex === 0
              ) {
                for (
                  let j = 0;
                  j < this.pricingDetail[i].products.length;
                  j++
                ) {
                  if (
                    this.pricingDetail[i].products[j].excess === event.excess &&
                    this.pricingDetail[i].products[j].isCruise ===
                      event.isCruise &&
                    this.pricingDetail[i].products[j].isSki === event.isSki
                  ) {
                    this.essentialPrice.selectedPrice =
                      this.pricingDetail[i].products[j];
                    this.selectedCover.selectedPrice =
                      this.pricingDetail[i].products[j];
                  }
                }
                this.tabs.forEach((obj) => {
                  if (obj.type === 'Tier2') {
                    obj.price.selectedPrice = this.essentialPrice.selectedPrice;
                  }
                });
                this.localCacheService.saveSessionStorage(
                  'essentialPrice',
                  this.essentialPrice,
                );
                this.localCacheService.saveSessionStorage(
                  'selectedCoverDetail',
                  this.selectedCover,
                );
              }
            } else if (this.isTier1) {
              if (
                this.pricingDetail[i].type === 'Tier1' &&
                this.selectedTabIndex === 0
              ) {
                for (
                  let j = 0;
                  j < this.pricingDetail[i].products.length;
                  j++
                ) {
                  if (
                    this.pricingDetail[i].products[j].excess === event.excess &&
                    this.pricingDetail[i].products[j].isCruise ===
                      event.isCruise
                  ) {
                    this.basicPrice.selectedPrice =
                      this.pricingDetail[i].products[j];
                    this.selectedCover.selectedPrice =
                      this.pricingDetail[i].products[j];
                  }
                }
                this.tabs.forEach((obj) => {
                  if (obj.type === 'Tier1') {
                    obj.price.selectedPrice = this.basicPrice.selectedPrice;
                  }
                });
                this.localCacheService.saveSessionStorage(
                  'basicPrice',
                  this.basicPrice,
                );
                this.localCacheService.saveSessionStorage(
                  'selectedCoverDetail',
                  this.selectedCover,
                );
              }
            }
          }
        }
      }
      if (event.isCruise) {
        this.selectedCover.selectedPrice['cruiseDates'] = event.cruiseDates;
      }
      if (event.isSki) {
        this.selectedCover.selectedPrice['skiiDates'] = event.skiDates;
      }
      this.localCacheService.saveSessionStorage(
        'selectedCoverDetail',
        this.selectedCover,
      );
      //Segment
      const segmentCoverPrice = this.selectedCover.selectedPrice;
      const selectedCoverTravel = {
        eventName: 'step_one_cover_change',
        planType: segmentCoverPrice.productType,
        excessAmount: segmentCoverPrice.excess,
        cruise: segmentCoverPrice.isCruise,
        ski: segmentCoverPrice.isSki,
        retailPrice: segmentCoverPrice.basePremium,
        selectedPriceDetails: segmentCoverPrice,
      };
      this.segmentService.segmentSingleTripOnChangePlanType(
        selectedCoverTravel,
      );
    }
  }

  onTripTypeClick(event: any, label: string) {
    this.selectedTripLabel = label;
    this.quoteEnquiry.tripType = label;
    this.selectedMobileTripType = label;
    this.loadValidations();
    this.toggleCalenderBasedOnTripSelection(label);
    const sydneyDate = new Date(
      new Intl.DateTimeFormat('en-US', { timeZone: 'Australia/Sydney' }).format(
        new Date(),
      ),
    );
    if (label === 'Single') {
      this.maxDepartDate = new Date();
      this.maxDepartDate.setDate(sydneyDate.getDate() + 548);
      this.maxDepartDate.setFullYear(this.maxDepartDate.getFullYear() + 1);
    } else {
      this.maxDepartDate = new Date();
      this.maxDepartDate.setDate(sydneyDate.getDate() + 548);
    }
  }

  getTripTypeLabel(tripType: string) {
    if (tripType === 'Single') {
      return 'Single';
    }
    return 'Annual Multi';
  }

  toggleCalenderBasedOnTripSelection(tripType: string) {
    this.updateQuoteForm.controls['rangeDates'].reset();
    if (tripType === 'Single') {
      let returnDateSingle;
      if (this.displayDefaultSingleDate === true) {
        returnDateSingle = new Date(this.quoteEnquiry.depart);
        returnDateSingle.setDate(
          returnDateSingle.getDate() + this.defaultReturnDateValueIncrement,
        );
        this.rangeDates = [
          new Date(this.quoteEnquiry.depart),
          returnDateSingle,
        ];
      } else {
        returnDateSingle = this.quoteEnquiry.return;
      }

      this.updateQuoteForm.controls['rangeDates'].setValue(this.rangeDates);

      this.departDate = moment(this.updateQuoteForm.value.rangeDates[0]).format(
        'DD/MM/YYYY',
      );
      this.returnDate = moment(
        this.updateQuoteForm.value.rangeDates[1] ||
          this.updateQuoteForm.value.rangeDates[0],
      ).format('DD/MM/YYYY'); // In case only one date is selected

      this.displayDepartDate = this.departDate;
      this.displayReturnDate = this.returnDate;
      this.quoteEnquiry.return = returnDateSingle;
    } else if (tripType === 'Multiple') {
      this.displayDefaultSingleDate = true;
      let returnDateAMT = new Date(this.quoteEnquiry.depart);
      returnDateAMT.setFullYear(returnDateAMT.getFullYear() + 1);
      returnDateAMT = new Date(
        returnDateAMT.setDate(returnDateAMT.getDate() - 1),
      );
      const depDate: any = new Date(this.quoteEnquiry.depart);
      this.rangeDates = depDate;
      this.quoteEnquiry.return = returnDateAMT;
      this.returnDateAMT = returnDateAMT;

      this.updateQuoteForm.controls['rangeDates'].setValue(this.rangeDates);
      this.displayDepartDate = moment(
        new Date(this.quoteEnquiry.depart),
      ).format('DD/MM/YYYY');
      this.displayReturnDate = moment(
        new Date(this.quoteEnquiry.return),
      ).format('DD/MM/YYYY');
      this.departDate = this.displayDepartDate;
      this.returnDate = this.displayReturnDate;
    }
  }

  onMemberClick(event: any, label: boolean) {
    if (label != this.initialQuoteFormValues.isClubMember) {
      this.hasQuoteFormValueChange = true;
    } else {
      this.hasQuoteFormValueChange = false;
    }
    this.selectedMemberLabel = label;
    this.quoteEnquiry.isClubMember = label;
    this.localCacheService.saveSessionStorage(
      'quoteEnquiry',
      this.quoteEnquiry,
    );
  }

  patchCountry() {
    this.updateQuoteForm.patchValue({
      travelDestination: this.selectedCountries,
    });
  }

  onTripLenghtClick(event: any, label: string) {
    this.selectedTripLenghtLabel = label;
    this.pricingDetail =
      this.localCacheService.getSessionStorage('pricingDetail') || {};
    this.selectedCover =
      this.localCacheService.getSessionStorage('selectedCoverDetail') || {};
    if (this.pricingDetail) {
      for (let i = 0; i < this.pricingDetail.length; i++) {
        if (this.pricingDetail[i].type === 'Annual Multi Trip') {
          for (let j = 0; j < this.pricingDetail[i].products.length; j++) {
            if (
              this.pricingDetail[i].products[j].excess ===
                this.selectedCover?.selectedPrice?.excess &&
              this.pricingDetail[i].products[j].multiTripLength ===
                this.selectedTripLenghtLabel &&
              this.pricingDetail[i].products[j].isCruise ===
                this.selectedCover?.selectedPrice?.isCruise &&
              this.pricingDetail[i].products[j].isSki ===
                this.selectedCover?.selectedPrice?.isSki
            ) {
              this.annualPrice.selectedPrice =
                this.pricingDetail[i].products[j];
              this.selectedCover.selectedPrice =
                this.pricingDetail[i].products[j];
            }
          }
          this.localCacheService.saveSessionStorage(
            'annualPrice',
            this.annualPrice,
          );
          this.localCacheService.saveSessionStorage(
            'selectedCoverDetail',
            this.selectedCover,
          );
          this.localCacheService.saveSessionStorage(
            'tripLenghtLabel',
            this.selectedTripLenghtLabel,
          );
        }
      }
    }
    //Segment
    const segmentCoverPrice = this.selectedCover.selectedPrice;
    const selectedCoverTravel = {
      eventName: 'step_one_amt_cover_quote_details',
      planType: segmentCoverPrice.productType,
      tripLength: segmentCoverPrice.selectedTripLenghtLabel,
      excessAmount: segmentCoverPrice.excess,
      cruise: segmentCoverPrice.isCruise,
      ski: segmentCoverPrice.isSki,
      retailPrice: segmentCoverPrice.basePremium,
      selectedPriceDetails: segmentCoverPrice,
    };
    this.segmentService.segmentSingleTripOnChangePlanType(selectedCoverTravel);
  }

  rangeDateSelect(event: any) {
    if (this.selectedTripLabel === 'Single') {
      if (this.updateQuoteForm.value.rangeDates) {
        this.departDate = moment(
          this.updateQuoteForm.value.rangeDates[0],
        ).format('DD/MM/YYYY');
        this.returnDate = moment(
          this.updateQuoteForm.value.rangeDates[1] ||
            this.updateQuoteForm.value.rangeDates[0],
        ).format('DD/MM/YYYY'); // In case only one date is selected
      }
    } else if (this.selectedTripLabel === 'Multiple') {
      if (this.updateQuoteForm.value.rangeDates) {
        this.departDate = moment(this.updateQuoteForm.value.rangeDates).format(
          'DD/MM/YYYY',
        );

        this.returnDateAMT = new Date(this.updateQuoteForm.value.rangeDates);
        this.returnDateAMT = new Date(
          this.returnDateAMT.setFullYear(this.returnDateAMT.getFullYear() + 1),
        );
        this.returnDateAMT = new Date(
          this.returnDateAMT.setDate(this.returnDateAMT.getDate() - 1),
        );
        this.returnDate = moment(this.returnDateAMT).format('DD/MM/YYYY');
        this.rangeDates = [
          this.updateQuoteForm.value.rangeDates,
          this.returnDateAMT,
        ];
      }
    }
  }

  applyDepartReturnDates() {
    // Logic to handle applying the selected dates
    // For demonstration, just logging the selected dates
    if (this.updateQuoteForm.value.rangeDates && this.updateQuoteForm.valid) {
      // Ensure the array has two elements
      const startDate = this.updateQuoteForm.value.rangeDates[0];
      const endDate =
        this.updateQuoteForm.value.rangeDates[1] ||
        this.updateQuoteForm.value.rangeDates[0]; // In case only one date is selected

      if (this.selectedTripLabel === 'Multiple') {
        this.displayDepartDate = this.departDate;
        this.displayReturnDate = this.returnDate;
      } else {
        this.displayDepartDate = this.departDate;
        this.displayReturnDate = this.returnDate;
        this.displayDefaultSingleDate = false;
      }

      this.departReturnDatePopup.toggle(
        event,
        this.departReturnDateBtn.nativeElement,
      );
    }
  }

  clearDepartReturnDates() {
    // Clears the selected dates
    this.displayDepartDate = moment(this.quoteEnquiry.depart).format(
      'DD/MM/YYYY',
    );
    this.displayReturnDate = moment(this.quoteEnquiry.return).format(
      'DD/MM/YYYY',
    );
  }

  updateQuote() {
    this.patchCountry();
    this.updateQuoteForm.get('age1')?.updateValueAndValidity();
    this.updateQuoteForm.get('age2')?.updateValueAndValidity();

    if (this.updateQuoteForm.valid) {
      let quoteEnquiry =
        this.localCacheService.getSessionStorage('quoteEnquiry');
      const isCruiseCountryBeforeUpdate =
        quoteEnquiry.travelDestination.filter(
          (item: any) => item.countryCode === 'AUC',
        ).length > 0;
      let newQuoteEnquiry = this.updateQuoteForm.value;
      quoteEnquiry.travelDestination = newQuoteEnquiry.travelDestination;
      if (this.selectedTripLabel === 'Single') {
        quoteEnquiry.depart = newQuoteEnquiry.rangeDates[0];
        quoteEnquiry.return = newQuoteEnquiry.rangeDates[1];
      } else if (this.selectedTripLabel === 'Multiple') {
        quoteEnquiry.depart = newQuoteEnquiry.rangeDates;
        quoteEnquiry.return = this.returnDateAMT;
      }
      quoteEnquiry.age1 = newQuoteEnquiry.age1;
      quoteEnquiry.age2 = newQuoteEnquiry.age2;
      quoteEnquiry.tripType = this.selectedTripLabel;
      quoteEnquiry.isSingleTrip =
        this.selectedTripLabel === 'Single' ? true : false;
      const isCruiseCountry = quoteEnquiry.travelDestination.find(
        (item: any) => item.countryCode === 'AUC',
      );
      this.isCruiseCountry = isCruiseCountry ? true : false;
      // if destination is changed with isCruise true, set isCruise to true to be default
      if (
        isCruiseCountryBeforeUpdate !== this.isCruiseCountry &&
        this.isCruiseCountry
      ) {
        this.selectedCover.selectedPrice.isCruise = this.isCruiseCountry;
        this.localCacheService.saveSessionStorage(
          'selectedCoverDetail',
          this.selectedCover,
        );
      }
      this.getAllBenefit();
      if (this.activatedClub !== 'W2C') {
        this.getAgeForIsSki(quoteEnquiry);
      }
      this.getPricing(quoteEnquiry);
      this.quoteEnquiry = quoteEnquiry;
      this.loadData(quoteEnquiry);
      this.showDiscountedPrice =
        this.quoteEnquiry.isClubMember === true ||
        this.quoteEnquiry.isClubMember === 'true' ||
        (this.quoteEnquiry.promoCode !== undefined &&
          this.quoteEnquiry.promoCode !== null &&
          this.quoteEnquiry.promoCode !== '');
      this.localCacheService.saveSessionStorage('quoteEnquiry', quoteEnquiry);
      let quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
      if (quoteDetail) {
        quoteDetail.isSingleTrip = quoteEnquiry.isSingleTrip;
        this.localCacheService.saveSessionStorage('quoteDetail', quoteDetail);
      }
      //Segment
      const tripDetails = {
        eventName: 'step_one_update_trip_details',
        selectedCountries: quoteEnquiry.travelDestination,
        departureDate: quoteEnquiry.depart,
        returnDate: quoteEnquiry.return,
        age1: quoteEnquiry.age1,
        age2: quoteEnquiry.age2,
        dependentsCount: quoteEnquiry.dependentsCount,
        state: quoteEnquiry.state,
        clubMember: quoteEnquiry.isClubMember,
        promoCode: quoteEnquiry.promoCode,
      };
      this.segmentService.segmentIdentifyUpdateQuote(tripDetails);
    } else {
      this.updateQuoteForm.markAllAsTouched();
      this.updateQuoteForm.markAsDirty();
      console.log('Form is not valid');
      console.log('this.updateQuoteForm', this.updateQuoteForm);
      this.handleErrors(this.updateQuoteForm);
    }
    this.loadValidations();
    this.triggerToggleClick();
  }

  handleErrors(form: FormGroup) {
    for (const key in form.controls) {
      if (form.controls[key].invalid) {
        if (key === 'travelDestination') {
          this.travelDestinationPopup.toggle(
            event,
            this.travelDestinationBtn.nativeElement,
          );
          break;
        } else if (key === 'rangeDates') {
          this.departReturnDatePopup.toggle(
            event,
            this.departReturnDateBtn.nativeElement,
          );
          break;
        } else if (key === 'age1' || key === 'age2') {
          this.travellersPopup.toggle(event, this.travellersBtn.nativeElement);
          break;
        }
      }
    }
    if (form.errors) {
      this.departReturnDatePopup.toggle(
        event,
        this.departReturnDateBtn.nativeElement,
      );
    }
  }

  getAllBenefit(): void {
    this.pricingService.getAllBenefits().subscribe((benefit: any) => {
      switch (environment.autoClub) {
        case 'RACT':
          this.allBenefits = benefit.ractAllbenefits;
          this.allTopSixBenefits = benefit.ractTopBenefits;
          if (this.isDomestic) {
            this.allBenefits = this.allBenefits.filter(
              (item: any) => item.Domestic,
            );
          }
          break;
        default:
          this.allBenefits = benefit.w2cAllbenefits;
          break;
      }
    });
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    if (this.country) {
      this.countryService
        .updateCountry(this.country)
        .subscribe(() => this.goBack());
    }
  }

  footerPopupclick() {
    this.proceedBtn.nativeElement.click();
  }

  submitTheQuoteDetail() {
    console.log('submit quote on step one');
    this.isButtonDisabled = true;
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 1500);
    this.quoteService
      .submitQuote('', 'step-one-product-select')
      .subscribe((response) => {
        if (!response.error) {
          console.log('submit quote on step one response', response);
          this.localCacheService.saveSessionStorage('quoteDetail', response);
          this.globalService.checkAndValidatePage(1, true);
          this.router.navigate(['/quotes-steps-two']);
        }
      });
  }

  getDestination() {
    if (this.quoteEnquiry?.travelDestination.length > 1) {
      return this.quoteEnquiry?.travelDestination
        .map((country: any) => country.countryName)
        .join(', ');
    } else {
      return this.quoteEnquiry?.travelDestination[0].countryName;
    }
  }

  getMobileTripType() {
    if (!this.quoteEnquiry?.isSingleTrip) {
      return 'Multiple';
    } else {
      return 'Single';
    }
  }

  getTravellersNum() {
    let travellers = 1;
    if (this.quoteEnquiry?.age2 !== '' && this.quoteEnquiry?.age2 !== 0) {
      travellers++;
    }
    return travellers;
  }

  getDependentsNum() {
    return Number(this.quoteEnquiry?.dependentsCount);
  }

  applyTravellerChanges(modalRef: any): void {
    if (this.updateQuoteForm.invalid) {
      return;
    }
    const values = this.updateQuoteForm?.value;
    if (values) {
      const age1 = values.age1;
      const age2 = values.age2;
      this.quoteDetail.ages[0] = Number(age1);
      if (this.quoteDetail.ages.length > 1) {
        this.quoteDetail.ages[1] = Number(age2);
      } else {
        this.quoteDetail.ages.push(Number(age2));
      }
      this.localCacheService.saveSessionStorage(
        'quoteDetail',
        this.quoteDetail,
      );

      this.quoteEnquiry.age2 = age2.toString();
      this.localCacheService.saveSessionStorage(
        'quoteEnquiry',
        this.quoteEnquiry,
      );
      if (modalRef !== undefined && modalRef !== null) {
        modalRef.hide();
      }
    }
  }

  applyDependentChanges(modalRef: any): void {
    if (this.updateQuoteForm.invalid) {
      return;
    }
    const values = this.updateQuoteForm?.value;
    if (values) {
      const dependentsCount = +values.dependentsCount;
      this.quoteDetail.dependentsCount = dependentsCount;
      this.localCacheService.saveSessionStorage(
        'quoteDetail',
        this.quoteDetail,
      );
      this.quoteEnquiry.dependentsCount = Number(dependentsCount);
      this.localCacheService.saveSessionStorage(
        'quoteEnquiry',
        this.quoteEnquiry,
      );
      if (modalRef !== undefined && modalRef !== null) {
        modalRef.hide();
      }
    }
  }

  downloadPDS() {
    let isMobileView = window.innerWidth < 768;
    const button = document.getElementById('downloadPDSButton');

    if (isMobileView) {
      if (button) {
        button.classList.remove('hidden');
      }
    }

    this.pdsService.downloadLatestPDSPDF();
    if (isMobileView) {
      if (button) {
        setTimeout(() => {
          button.classList.add('hidden');
        }, 1000);
      }
    }
  }

  setPriceLabels() {
    switch (this.activatedClub.toUpperCase()) {
      case 'W2C':
        this.standardPriceLabel = 'Standard Price';
        this.discountedPriceLabel = 'Your Price';
        break;

      default:
        this.standardPriceLabel = 'Regular Price';
        this.discountedPriceLabel = 'Member Price';
    }
  }

  hasCovid19Coverage() {
    const covidBenefits = this.allBenefits.filter((x) =>
      x.benefits.toLowerCase().includes('covid-19'),
    );
    const hasCovidBenefits =
      covidBenefits.filter(
        (x) => x[this.selectedCover.selectedCoverTitle] !== 'Not Applicable',
      ).length > 0;
    return hasCovidBenefits;
  }

  hasCovid19CoverageForRACV() {
    switch (this.selectedCover.selectedCoverTitle) {
      case 'Essentials Cover':
      case 'Essential Cover':
      case 'Essentials':
      case 'Basics Cover':
      case 'Basic Cover':
      case 'Basics':
      case 'Domestic cancellation':
      case 'Rental Car Excess':
        return false;
      default:
        return true;
    }
  }

  hasCovid19CoverageForW2C() {
    switch (this.selectedCover.selectedCoverTitle) {
      case 'Essentials Cover':
        return false;
      case 'Basics Cover':
        return false;
      default:
        return true;
    }
  }

  setDefaultValuesForValidationRules(validationsList: any[]) {
    validationsList.forEach((validation: any) => {
      if (validation.field === 'returnDateDefaultValue') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'defaultValue') {
            this.defaultReturnDateValueIncrement = rule.value;
            return;
          }
        });
      } else if (validation.field === 'first_age') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.firstMinAge = rule.value;
          } else if (rule.type === 'max') {
            this.firstMaxAge = rule.value;
          }
        });
      } else if (validation.field === 'second_age') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.secondMinAge = rule.value;
          }
          if (rule.type === 'max') {
            this.secondMaxAge = rule.value;
          }
        });
      } else if (validation.field === 'first_age_multi') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.firstMultiMinAge = rule.value;
          } else if (rule.type === 'max') {
            this.firstMultiMaxAge = rule.value;
          }
        });
      } else if (validation.field === 'second_age_multi') {
        validation.rules.forEach((rule: Rule) => {
          if (rule.type === 'min') {
            this.secondMultiMinAge = rule.value;
          }
          if (rule.type === 'max') {
            this.secondMultiMaxAge = rule.value;
          }
        });
      }
    });
    this.updateQuoteForm.get('age1')?.setValidators([]);
    this.updateQuoteForm.get('age2')?.setValidators([]);

    if (this.quoteEnquiry.tripType === 'Single') {
      this.updateQuoteForm
        .get('age1')
        ?.addValidators([
          Validators.required,
          Validators.min(this.firstMinAge),
          Validators.max(this.firstMaxAge),
        ]);
      this.updateQuoteForm
        .get('age2')
        ?.addValidators([
          Validators.min(this.secondMinAge),
          Validators.max(this.secondMaxAge),
        ]);
    } else {
      this.updateQuoteForm
        .get('age1')
        ?.addValidators([
          Validators.required,
          Validators.min(this.firstMultiMinAge),
          Validators.max(this.firstMultiMaxAge),
        ]);
      this.updateQuoteForm
        .get('age2')
        ?.addValidators([
          Validators.min(this.secondMultiMinAge),
          Validators.max(this.secondMultiMaxAge),
        ]);
    }
  }

  searchFn = (term: string, item: any) => {
    if (term.length < 2) return false;
    return item.countryName.toLowerCase().startsWith(term.toLowerCase());
  };
  onModelChange(event: any) {
    this.selectedCountries = event;
    this.searchTerms = '';
    this.patchCountry();
    this.filteredCountries = [];
  }
  countrySearchFn(searchTerms: any) {
    this.searchTerms = searchTerms?.term;
    this.filteredCountries = [...this.allCountries]
    if (this.searchTerms.length >= 2) {
      this.filteredCountries = this.allCountries.filter(
        (country) =>
          country.countryName.toLowerCase().startsWith(this.searchTerms.toLowerCase()) &&
          !this.selectedCountries?.includes(country)
      );
    } else {
      this.filteredCountries = [];
    }
  }
  handlecountrySearcBlur(event: any) {
    this.ngSelectIsFocused = false;
    if (event && event.target) {
      event.target.value = '';
    }
    this.searchTerms = '';
  }
  goToStepOne() {
    sessionStorage.clear();
    setTimeout(() => {
      this.router.navigate(['']);
    }, 100);
  }
  triggerSegmentEvent(field: string) {
    let eventVal: any;
    let eventNameTitle: any;

    switch (field) {
      case 'saveEmailMain':
        eventNameTitle = 'step_one_body_save_and_email_quote';
        break;
      case 'downloadPdsMain':
        eventNameTitle = 'step_one_body_downlaod_pds';
        break;
      case 'saveEmailSideIcon':
        eventNameTitle = 'step_one_side_icon_save_and_email_quote';
        break;
      case 'downloadPdslSideIcon':
        eventNameTitle = 'step_one_side_icon_downlaod_pds';
        break;
    }

    const travelDetails = {
      eventName: eventNameTitle,
      value: eventVal,
    };

    this.segmentService.segmentIdentifyEventNameAndValue(travelDetails);
  }

  triggerSegmentQuoteDetails() {
    const segmentCoverPrice = this.selectedCover.selectedPrice;
    const productPlans = {
      eventName: 'step_one_quote_trip_details',
      tripType: segmentCoverPrice?.selectedTripLabel,
      tripLength: segmentCoverPrice?.selectedTripLenghtLabel,
      selectedCountries: this.quoteEnquiry.travelDestination,
      departureDate: this.quoteEnquiry.depart,
      returnDate: this.quoteEnquiry.return,
      age1: this.quoteEnquiry.age1,
      age2: this.quoteEnquiry.age2,
      dependentsCount: this.quoteEnquiry.dependentsCount,
      state: this.quoteEnquiry.state,
      promoCode: this.quoteEnquiry.promoCode,
      planType: segmentCoverPrice?.productType,
      excessAmount: segmentCoverPrice?.excess,
      cruise: segmentCoverPrice?.isCruise,
      ski: segmentCoverPrice?.isSki,
      retailPrice: segmentCoverPrice?.basePremium,
      selectedPriceDetails: segmentCoverPrice,
    };

    this.segmentService.segmentQuoteTripPlanDetails(productPlans);
  }
}
